import { LanguageIsoType } from "./LanguageIsoType";
import { ProductHierarchy } from "./ProductHierarchy";

export class ProductHierarchyByName {
  id = "";
  name = "";
  level1 = "";
  level2 = "";
  level3 = "";
  level4 = "";
  level5 = "";

  public static create(productHierarchy: ProductHierarchy, languageIso: LanguageIsoType): ProductHierarchyByName {
    const productHierarchyByName = new ProductHierarchyByName();
    productHierarchyByName.id = productHierarchy.id;

    productHierarchyByName.level1 =
      productHierarchy.level1?.names.find((name) => name.languageIso === languageIso)?.text || "";
    productHierarchyByName.level2 =
      productHierarchy.level2?.names.find((name) => name.languageIso === languageIso)?.text || "";
    productHierarchyByName.level3 =
      productHierarchy.level3?.names.find((name) => name.languageIso === languageIso)?.text || "";
    productHierarchyByName.level4 =
      productHierarchy.level4?.names.find((name) => name.languageIso === languageIso)?.text || "";
    productHierarchyByName.level5 =
      productHierarchy.level5?.names.find((name) => name.languageIso === languageIso)?.text || "";

    productHierarchyByName.name = productHierarchyByName.level1;
    if (productHierarchyByName.level2 != "") productHierarchyByName.name += ` ${productHierarchyByName.level2}`;
    if (productHierarchyByName.level3 != "") productHierarchyByName.name += ` ${productHierarchyByName.level3}`;
    if (productHierarchyByName.level4 != "") productHierarchyByName.name += ` ${productHierarchyByName.level4}`;
    if (productHierarchyByName.level5 != "") productHierarchyByName.name += ` ${productHierarchyByName.level5}`;

    return productHierarchyByName;
  }
}

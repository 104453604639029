import { ref, onMounted, onUnmounted } from "vue";

export function useOrderSidebar() {
  const showSidebar = ref(false);
  const useOverlaySidebar = ref(false);

  const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value;
    if (!useOverlaySidebar.value) {
      localStorage.setItem("order.showSidebar", showSidebar.value.toString());
    }
  };

  const windowWidth = ref(0);
  const checkScreen = () => {
    const isBrowser = typeof window !== "undefined";
    windowWidth.value = isBrowser ? window.innerWidth : 0;
    useOverlaySidebar.value = windowWidth.value < 998 ? true : false;
  };
  onMounted(() => {
    checkScreen();
    window.addEventListener("resize", checkScreen);

    if (!useOverlaySidebar.value) {
      const value = localStorage.getItem("order.showSidebar");
      if (value != null) {
        showSidebar.value = value === "true" ? true : false;
      }
    }
  });
  onUnmounted(() => {
    window.removeEventListener("resize", checkScreen);
  });

  return {
    showSidebar,
    useOverlaySidebar,
    toggleSidebar,
  };
}

import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const mainRoutes: Array<RouteRecordRaw> =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/b2c/OAuth2Redirect.vue"),
        },
        {
          name: "Logout",
          path: "/logout",
          component: (): Component => import("../components/b2c/Logout.vue"),
        },
        {
          path: "/failed",
          name: "Failed",
          component: (): Component => import("../components/b2c/Failed.vue"),
        },
      ]
    : [];

export const orderRoutes = [
  {
    path: "/order/add",
    name: "order-add",
    component: (): Component => import("@/views/OrderAdd.vue"),
    meta: {
      authRequired: true,
      title: "order.add-order",
    },
  },
  {
    path: "/order/edit/:id",
    name: "order-edit",
    component: (): Component => import("@/views/OrderEdit.vue"),
    meta: {
      authRequired: true,
      title: "order.edit-order",
    },
  },
  {
    path: "/order/search/:search?",
    name: "order-search",
    component: (): Component => import("@/views/OrderSearch.vue"),
    meta: {
      authRequired: true,
      title: "order.search-order",
    },
  },
];

<template>
  <Popover
    ref="customerPanelRef"
    appendTo="body"
    :dismissable="true"
    id="customer_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '800px' }"
    class="customer-panel"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <CustomerList @selectedCustomerId="selectedCustomerId" />
  </Popover>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CustomerList from "./CustomerList.vue";

const customerPanelRef = ref();
const emit = defineEmits<{ (e: "selectedCustomerId", value: string): void }>();
const toggle = (event: Event) => customerPanelRef.value.toggle(event);
const show = (event: Event) => customerPanelRef.value.show(event);
const hideOverlayPanel = () => customerPanelRef.value.hide();

defineExpose({ toggle: toggle, show: show });

const selectedCustomerId = (id: string) => {
  customerPanelRef.value.hide();
  emit("selectedCustomerId", id);
  document.getElementById("contact-search-input")?.focus();
};
</script>

<style scoped lang="scss">
.customer-panel .p-overlaypanel-content {
  height: auto;
}
</style>

export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  css: ({ dt }) => `
      .c-input-minimal .p-inputgroupaddon:last-child, 
      .c-input-minimal .p-inputgroup > .p-component:last-child, 
      .c-input-minimal .p-inputgroup > .p-inputwrapper:last-child > .p-component, 
      .c-input-minimal .p-inputgroup > .p-floatlabel:last-child > .p-component, 
      .c-input-minimal .p-inputgroup > .p-floatlabel:last-child > .p-inputwrapper > .p-component, 
      .c-input-minimal .p-inputgroup > .p-iftalabel:last-child > .p-component, 
      .c-input-minimal .p-inputgroup > .p-iftalabel:last-child > .p-inputwrapper > .p-component {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .c-input-minimal .p-inputgroupaddon:last-child {
        border-top: none !important;
        border-right: none !important;
      }

      .c-input-filled .p-inputgroupaddon {
        background: ${dt("color-mix(in srgb, {primary.50}, transparent 50%)")} !important;
      }    
      .dark .c-input-filled .p-inputgroupaddon {
        background: ${dt("color-mix(in srgb, {form.field.background}, transparent 50%)")} !important;            
      }
      `,
};

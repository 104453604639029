<template>
  <FloatLabelDropdown
    id="freight-method"
    dataTestId="freight-method"
    v-model:value="selectedFreightMethod"
    :options="dropdownOptions"
    :class="{ 'p-invalid': val.freightMethod.$error, 'p-disabled': !allowEdit }"
    :label="t(`order.freight-method`)"
    :placeholder="t('placeholder.select', { property: t('order.freight-method').toLowerCase() })"
    :isFloatingLabel="isFloatingLabel"
    :disabled="!allowEdit"
  />

  <small class="ml-6 p-error" v-if="val.freightMethod.$error" data-testid="freight-method-error">
    {{ val.freightMethod.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { FreightMethod } from "@/models/freight-method/FreightMethod";
import { useFreightMethod } from "@/api/freight-method/FreightMethodService";
import { OrderFreightMethod } from "@/models/order/OrderFreightMethod";
import { OrderStatus } from "@/models/order/OrderStatus";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();
const freightMethods = ref<FreightMethod[]>([]);
const { getFreightMethodsForCustomers } = useFreightMethod();

const props = defineProps<{
  freightMethod: OrderFreightMethod;
  defaultFreightMethodId: string;
  orderStatus?: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:freightMethod", value: OrderFreightMethod): void;
  (e: "reCalculateOrderTotalPrice"): void;
}>();

const dropdownOptions = computed(() => {
  return freightMethods.value.map((freightMethod) => {
    return { name: freightMethod.name, value: freightMethod.id };
  });
});

const selectedFreightMethod = computed<string>({
  get: () => {
    return freightMethods.value.find((fm) => fm.id === props.freightMethod?.id)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const fm = freightMethods.value.find((fm) => fm.id === id);
    if (fm && allowEdit.value === true) {
      const orderFreightMethod = new OrderFreightMethod();
      orderFreightMethod.id = fm.id;
      orderFreightMethod.name = fm.name;
      orderFreightMethod.taxRate = fm.tax?.taxRate ?? 0;
      orderFreightMethod.bring = fm.bring;

      emit("update:freightMethod", orderFreightMethod);
      emit("reCalculateOrderTotalPrice");
    }
  },
});

watch(
  () => props.defaultFreightMethodId,
  (id: string) => (selectedFreightMethod.value = id),
);

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});

const fetchFreightMethods = async () => {
  freightMethods.value = await getFreightMethodsForCustomers();
};
onMounted(fetchFreightMethods);

const rules = {
  freightMethod: {
    name: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);

const isFloatingLabel = computed(() => {
  if (selectedFreightMethod.value === undefined) {
    return false;
  }

  return selectedFreightMethod.value !== "";
});
</script>

import { defineStore } from "pinia";
import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "../api/search/SearchApi";
import { OrderSearchRequest } from "@/models/search/order/OrderSearchRequest";
import { OrderSearchResponse } from "@/models/search/order/OrderSearchResponse";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useOrderSearchStore = defineStore("orderSearch", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const orderRequest = ref<OrderSearchRequest | null>(null);
  const orderResponse = ref<OrderSearchResponse | null>(null);

  const loading = ref(false);

  const orderSearch = async (request: OrderSearchRequest, clearCache = false): Promise<OrderSearchResponse> => {
    try {
      loading.value = true;

      if (clearCache) {
        orderRequest.value = null;
        orderResponse.value = null;
      }

      const authHeaders = await getAuthHeaders();

      if (orderRequest.value && orderRequest.value?.equals(request) && orderResponse.value !== null) {
        return orderResponse.value;
      }

      orderResponse.value = await searchApi.orderSearch(authHeaders, request);
      orderRequest.value = request;
      return orderResponse.value;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return new OrderSearchResponse();
  };

  return { loading, orderSearch };
});

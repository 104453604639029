<template>
  <div id="editor" ref="quillEditor" class="quill-editor"></div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from "vue";
import Quill from "quill";

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const quillEditor = ref<Quill | null>(null);

const initializeQuill = () => {
  quillEditor.value = new Quill("#editor", {
    theme: "snow",
    readOnly: true,
    modules: {
      toolbar: null,
    },
  });

  quillEditor.value.root.innerHTML = props.modelValue;

  quillEditor.value.on("text-change", () => {
    emit("update:modelValue", quillEditor.value?.root.innerHTML || "");
  });
};

onMounted(() => {
  nextTick(() => {
    initializeQuill();
  });
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (quillEditor.value) {
      quillEditor.value.root.innerHTML = newValue;
    }
  },
);
</script>

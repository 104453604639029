export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  css: ({ dt }) => `
      .c-input-minimal .p-select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;      
      }    
      .c-input-minimal .p-select-label {
        padding-left: 0.2rem;
      }
      
      .c-input-filled .p-select {
        background: ${dt("color-mix(in srgb, {primary.50}, transparent 50%)")};            
      }    
      .dark .c-input-filled .p-select {
        background: ${dt("color-mix(in srgb, {form.field.background}, transparent 50%)")};            
      }
      `,
};

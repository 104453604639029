<template>
  <div class="sticky" :class="{ 'c-fullscreen': showAdvancedSearch == true }">
    <div v-if="props.allowAddProducts" class="w-full">
      <div class="flex flex-wrap mt-3">
        <div class="min-w-full lg:min-w-0 lg:w-4/12 xl:w-4/12">
          <InputText
            id="order-product-search"
            ref="searchInput"
            class="py-3 leading-4 bg-[#F0F6FA] text:slate-950 rounded border-none focus:border-solid hover:border hover:border-solid border-[#F0F6FA] hover:bg-[#F0F6FA] hover:border-[#F0F6FA] active:bg-[#F0F6FA] active:border-[#F0F6FA] focus:bg-[#F0F6FA] focus:border-[#F0F6FA] dark:bg-stone-700 dark:text:stone-200 dark:border-stone-950 dark:hover:bg-stone-600 dark:hover:border-stone-500 dark:active:bg-stone-600 dark:active:border-stone-500 dark:focus:bg-stone-600 dark:focus:border-stone-500"
            v-debounce:200="search"
            v-model="query"
            :fluid="true"
            autocomplete="off"
            aria-controls="product_overlay_panel"
            data-testid="order-product-search"
            :placeholder="t('order.product-search')"
            :disabled="!props.allowAddProducts"
            @keydown.enter.prevent="focusSearchResult"
            @keydown.tab="tabKeydownInSearch"
            @keydown.shift.tab.prevent="focusFirstOrderLine = true"
            @focus="editingRowIndex = null"
          />
          <span class="p-button bg-transparent border-none z-10 -ml-12"><i class="pi pi-search text-zinc-500" /></span>
        </div>

        <div class="min-w-full lg:min-w-0 lg:w-4/12 xl:w-4/12 mb-4 lg:mb-0">
          <Button
            class="c-tool-material ml-4"
            data-testid="btn-advanced-search"
            variant="outlined"
            severity="secondary"
            @click="toggleAdvancedSearch"
          >
            <span class="material-symbols-outlined text-lg">
              {{ showAdvancedSearch ? "shopping_bag" : "manage_search" }}</span
            >
            <span>{{ showAdvancedSearch ? t("order.view-order") : t("order.search-mode") }}</span>
          </Button>
        </div>

        <div class="min-w-full lg:min-w-0 lg:w-4/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right"></div>
      </div>
    </div>
  </div>

  <div class="c-product-search min-w-full" :class="{ 'c-fullscreen': showAdvancedSearch == true }">
    <DataTable
      id="order-product-search-result"
      ref="tableProductsSearchResult"
      :value="products"
      stripedRows
      dataKey="id"
      class="c-datatable c-product-search-result"
      :class="{
        hidden: !showSearchResult && !showAdvancedSearch,
      }"
      responsiveLayout="scroll"
      selectionMode="single"
      :loading="loadingSearch"
      :rowHover="true"
      data-testid="order-product-search-result"
      :resizableColumns="true"
      columnResizeMode="fit"
      pt:footer:class="border-b-0"
      @row-select="onRowSelected"
      @row-dblclick="onSelectProduct"
      @keydown="handleKeyPress($event)"
      @rowContextmenu="onRowContextMenu"
    >
      <Column field="productImages" :header="t('order.properties.main-image')" class="p-0">
        <template #body="{ data }">
          <img
            v-if="data.productImages.length > 0"
            :src="resizeImage(data.productImages[0].url, 30, 30)"
            class="c-product-image"
          />
        </template>
      </Column>

      <Column field="productNumber" :header="t('order.properties.product-number')"></Column>
      <Column :header="t('order.properties.product-name')">
        <template #body="{ data }">
          <div class="w-80 whitespace-nowrap text-ellipsis">{{ data.name }}</div>
        </template>
      </Column>
      <Column :header="t('order.properties.in-stock')" class="text-right">
        <template #body="{ data }">
          {{ getProductStock(data) }}
        </template>
      </Column>
      <Column field="priceIncVat" :header="t('order.properties.price-incl-vat')" class="text-right">
        <template #body="{ data }">
          {{ n(data.prices[0]?.listPriceIncVat ?? 0, "decimal") }}
        </template>
      </Column>
      <Column field="priceExcVat" :header="t('order.properties.price-excl-vat')" class="text-right">
        <template #body="{ data }">
          {{ n(data.prices[0]?.listPrice ?? 0, "decimal") }}
        </template>
      </Column>
      <Column field="costPrice" :header="t('order.properties.cost-price')" class="text-right">
        <template #body="{ data, index }">
          <span :data-testid="`product-search-cost-price-${index}`">{{ n(getCostPrice(data), "decimal") }}</span>
        </template>
      </Column>

      <Column
        class="c-col-<quantity> text-center p-1"
        field="quantity"
        :header="t('order.properties.quantity')"
        style="min-width: 5rem; padding: 0"
      >
        <template #body="{ data, field, index }">
          <OrderLineInput
            v-if="editingRowIndex === index && !isNaN(data[field as keyof typeof data])"
            :id="`product-search-quantity-${index}`"
            :disabled="!props.allowAddProducts"
            inputClass="c-row-input w-16"
            :value="data[field as keyof typeof data]"
            :minValue="minQuantity(data)"
            :allowEmpty="false"
            :setFocus="true"
            data-testid="product-search-quantity"
            @onUpdateValue="updateQuantity($event, data)"
            @keydown.enter.prevent="createNewOrderLine(data, data.quantity, index)"
            @keydown.esc.stop="stopEditRow(index)"
          />
        </template>
      </Column>

      <Column
        :header="t('order.properties.discounted-product-price-ex-vat')"
        class="text-right w-32"
        style="padding: 0"
      >
        <template #body="{ data, index }">
          <OrderLineInput
            v-if="editingRowIndex === index && data.prices[0]?.discountedPrice != null"
            :id="`product-search-discounted-price-${index}`"
            :disabled="!props.allowAddProducts"
            inputClass="c-row-input w-20"
            :value="data.prices[0].discountedPrice"
            :minValue="0"
            :digits="2"
            :allowEmpty="false"
            data-testid="product-search-discounted-price"
            @onUpdateValue="updatePriceExVat($event, data)"
            @keydown.enter.prevent="createNewOrderLine(data, data.quantity, index)"
            @keydown.esc.stop="stopEditRow(index)"
          />
          <span
            v-if="
              editingRowIndex !== index &&
              data.prices[0]?.discountedPrice != null &&
              data.prices[0]?.listPrice != null &&
              data.prices[0].discountedPrice != data.prices[0].listPrice
            "
            :data-testid="`product-search-discounted-price-${index}`"
            >{{ n(data.prices[0]?.discountedPrice, "decimal") }}</span
          >
        </template>
      </Column>

      <Column :header="t('order.properties.discounted-product-percentage')" class="text-right w-32" style="padding: 0">
        <template #body="{ data, index }">
          <OrderLineInput
            v-if="editingRowIndex === index"
            :id="`product-search-discount-percentage-${index}`"
            :disabled="!props.allowAddProducts"
            inputClass="c-row-input w-20"
            :value="data.prices[0]?.discountPercentage ?? 0"
            :minValue="0"
            :digits="2"
            :allowEmpty="false"
            data-testid="product-search-discounted-percentage"
            @onUpdateValue="updateDiscount($event, data)"
            @keydown.enter.prevent="createNewOrderLine(data, data.quantity, index)"
            @keydown.esc.stop="stopEditRow(index)"
          />

          <span
            v-if="
              editingRowIndex !== index &&
              data.prices[0]?.discountPercentage != null &&
              data.prices[0]?.discountPercentage > 0
            "
            :data-testid="`product-search-discounted-percentage-${index}`"
          >
            {{ n(data.prices[0]?.discountPercentage, "decimal") }}
          </span>
        </template>
      </Column>
      <Column :header="t('order.properties.contribution-margin')" class="text-right w-32" style="padding: 0">
        <template #body="{ data, index }">
          <OrderLineInput
            v-if="editingRowIndex === index && data.prices[0]?.contributionMargin != null"
            :id="`product-search-contribution-margin-${index}`"
            :disabled="!props.allowAddProducts"
            inputClass="c-row-input w-20"
            :value="data.prices[0]?.contributionMargin ?? 0"
            :minValue="0"
            :digits="2"
            :allowEmpty="false"
            data-testid="product-search-contribution-margin"
            @onUpdateValue="updateContributionMargin($event, data)"
            @keydown.enter.prevent="createNewOrderLine(data, data.quantity, index)"
            @keydown.esc.stop="stopEditRow(index)"
          />
          <span
            v-if="editingRowIndex !== index && data.prices[0]?.contributionMargin != null"
            :data-testid="`product-search-contribution.margin-${index}`"
            >{{ n(data.prices[0]?.contributionMargin, "decimal") }}</span
          >
        </template>
      </Column>

      <Column class="text-center" style="width: 3.5rem">
        <template #header>
          <div class="text-center w-full">{{ t("order.properties.alternatives") }}</div>
        </template>
        <template #body="{ data, index }">
          <Button
            v-if="data.alternativeProductIds?.length > 0"
            text
            label="A"
            type="button"
            class="c-alternatives-button"
            :data-testid="`product-search-alternatives-${index}`"
            :tabindex="editingRowIndex === index ? -1 : 0"
            @click="accessoryStore.showAlternativesDialog(data.alternativeProductIds ?? [])"
          />
        </template>
      </Column>

      <Column class="text-center" style="width: 3.5rem">
        <template #header>
          <div class="text-center w-full">{{ t("order.properties.accessory") }}</div>
        </template>
        <template #body="{ data, index }">
          <Button
            v-if="data.accessoryProductIds?.length > 0"
            text
            label="T"
            type="button"
            class="c-accessory-button"
            :data-testid="`product-search-accessories-${index}`"
            :tabindex="editingRowIndex === index ? -1 : 0"
            @click="accessoryStore.showAccessoryDialog(data.accessoryProductIds)"
          />
        </template>
      </Column>

      <Column class="text-center p-0 w-12">
        <template #body="{ data, index }">
          <div class="w-12">
            <div v-if="editingRowIndex !== index" class="c-product-search-select">
              <div class="flex justify-center items-center">
                <i
                  role="button"
                  class="pi pi-ellipsis-h c-context-menu-button"
                  :data-testid="`context-menu-button-${index}`"
                  @click="(e) => openEllipsisContextMenu(e, data, index)"
                >
                </i>
              </div>
            </div>

            <div v-else class="c-product-search-edit">
              <div class="flex justify-center items-center">
                <Button
                  text
                  type="button"
                  data-testid="product-search-add"
                  class="p-0"
                  @click="createNewOrderLine(data, data.quantity, index)"
                >
                  <span class="c-row-add material-symbols-outlined material-filled"> add_circle </span>
                </Button>
              </div>
            </div>

            <div v-if="editingRowIndex !== index && isUsed(data, props.orderLines)">
              <span class="c-product-search-used material-symbols-outlined material-filled"> check_circle </span>
            </div>
          </div>
        </template>
      </Column>

      <template #empty>
        <div v-if="query === ''">{{ t("order.search-input-is-empty") }}</div>
        <div v-else>{{ t("order.no-products-found") }}</div>
      </template>

      <template #footer>
        <div class="flex justify-center">
          <div v-tooltip.bottom="showMoreSearchResultTooltip" class="inline-block w-1/2">
            <Button
              v-show="showMoreSearchResult"
              class="w-full"
              :label="t('common.show-more-search-result')"
              @click="loadMoreSearchResult"
            />
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <RowContextMenu
    ref="ellipsisContextMenuRef"
    :allowDelete="false"
    :allowEdit="allowEditOrder"
    @openProductInfo="openProductInfoDialog(focusedRow.id)"
    @selectRow="selectRow(focusedRow.value, focusedRowIndex)"
  />
  <ProductInfoDialog v-if="showDialog" v-model:showDialog="showDialog" :productId="selectedProductId" />

  <Dialog
    contentStyle="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;"
    :header="`${t('order.complementary-items.accessories')}(${accessoryProductIds?.length ?? 0})`"
    :visible="accessoryProductIds != null"
    :dismissableMask="true"
    modal
    @update:visible="accessoryStore.closeAccessoryDialog"
  >
    <Accessories @addProducts="onAddProducts" @cancel="accessoryStore.closeAccessoryDialog" />
  </Dialog>
  <Dialog
    contentStyle="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;"
    :header="`${t('order.complementary-items.alternatives')}(${alternativeProductIds?.length ?? 0})`"
    :visible="alternativeProductIds != null"
    :dismissableMask="true"
    modal
    @update:visible="accessoryStore.closeAlternativesDialog"
  >
    <Alternatives @addProducts="onAddProducts" @cancel="accessoryStore.closeAlternativesDialog" />
  </Dialog>
</template>

<script setup lang="ts">
import { nextTick, ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import type {
  DataTableRowContextMenuEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
} from "primevue/datatable";

import { useImageService } from "@/api/image/ImageService";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import type { SearchProductViewModel } from "@/models/search/product/view-model/SearchProductViewModel";
import { useDiscountService } from "@/api/discount/DiscountService";
import { useProductSearchService } from "@/api/search/ProductSearchService";
import { useOrderShortcuts } from "@/utils/OrderShortcuts";
import { OrderShortcutAction } from "@/utils/OrderShortcutAction";
import type { ProductAvailability } from "@/models/search/product/ProductAvailability";

import Accessories from "../components/Accessories.vue";
import Alternatives from "../components/Alternatives.vue";

import { ProductPricesRequest } from "@/models/discount/ProductPricesRequest";

import { OrderProduct } from "@/models/order/product/OrderProduct";
import { CalculationBase } from "@/models/order/CalculationBase";
import { OrderLine } from "@/models/order/OrderLine";

import { useOrderCalculation } from "@/utils/calculation/OrderCalculation";
import OrderLineInput from "./OrderLineInput.vue";
import { useAccessoryStore } from "@/stores/AccessoryStore";
import ProductInfoDialog from "./ProductInfoDialog.vue";
import RowContextMenu from "./RowContextMenu.vue";

const toast = useCumulusToast(useToast());
const { resizeImage } = useImageService();
const { getProductPrices } = useDiscountService();
const { t, n } = useI18n();

const accessoryStore = useAccessoryStore();

const { accessoryProductIds, alternativeProductIds } = storeToRefs(accessoryStore);

const props = defineProps<{
  allowAddProducts: boolean;
  orderLines: OrderLine[];
  customerId: string;
  defaultWarehouseId: string;
  allowEditOrder: boolean;
}>();

const showAdvancedSearch = defineModel<boolean>("showAdvancedSearch", { required: true });
const selectedProductId = defineModel<string | null>("selectedProductId", { required: true });
const focusFirstOrderLine = defineModel<boolean>("focusFirstOrderLine", { required: true });

const emit = defineEmits<{
  (e: "createNewOrderLine", value: { orderLine: OrderLine; warehouseId: string }): void;
}>();

const { calculateOrderLinePrice } = useOrderCalculation();

const tableProductsSearchResult = ref();
const loadingSearch = ref(false);
const searchInput = ref();
const products = ref<SearchProductViewModel[]>([]);
const totalHits = ref(0);
const query = ref("");
const showSearchResult = ref(false);
const showDialog = ref<boolean>(false);
const focusedRow = ref();
const focusedRowIndex = ref<number>(0);
const previouslyFocusedRow = ref();
const ellipsisContextMenuRef = ref();

const editingRowIndex = ref<number | null>(null);

const onAddProducts = (addedProducts: Map<SearchProductViewModel, number>) => {
  addedProducts.forEach((quantity, product) => {
    createNewOrderLine(product, quantity);
  });

  accessoryStore.closeAccessoryDialog();
  accessoryStore.closeAlternativesDialog();
};

const createNewOrderLine = async (product: SearchProductViewModel, quantity: number, index: number = -1) => {
  const orderProduct = OrderProduct.createFromProduct(product);
  const orderLine = OrderLine.create(orderProduct, product);
  orderLine.price = product.prices[0].discountedPrice ?? orderLine.price;
  orderLine.quantity = quantity;
  const response = calculateOrderLinePrice(orderLine, CalculationBase.Price);

  orderLine.price = response.price;
  orderLine.priceIncVat = response.priceIncVat;
  orderLine.contributionMargin = response.contributionMargin;
  orderLine.discountPercentage = response.discountPercentage;
  orderLine.sumLine = response.sumLine;
  orderLine.quantity = quantity;

  emit("createNewOrderLine", { orderLine, warehouseId: props.defaultWarehouseId });
  editingRowIndex.value = null;

  toast.add({
    severity: "success",
    summary: t("order.toast-add-product-summary", { quantity: quantity }),
    detail: product.name,
    life: 1000,
  });

  if (!showAdvancedSearch.value) {
    focusSearch();
    return;
  }

  stopEditRow(index);
};

const stopEditRow = (index: number) => {
  editingRowIndex.value = null;

  if (tableProductsSearchResult.value) {
    tableProductsSearchResult.value.$el.querySelectorAll("tbody tr")[index]?.focus();
  }
};

const toggleAdvancedSearch = () => {
  showAdvancedSearch.value = !showAdvancedSearch.value;
  if (showAdvancedSearch.value) {
    nextTick(() => {
      focusSearch();
    });
  }
};

const focusSearch = () => {
  const el = searchInput.value?.$el;
  if (el != null) {
    el.focus();
    el.select();
    search();
  }
};

const scrollSearchResultIntoView = () => {
  const el = searchInput.value?.$el as HTMLInputElement;
  if (el != null) {
    const innerHeight = window.innerHeight - el.getBoundingClientRect().y;
    if (innerHeight < 200) {
      tableProductsSearchResult.value?.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
};

const { productSearch } = useProductSearchService();

const showMoreSearchResult = computed(() => {
  return products.value.length < totalHits.value;
});
const loadMoreSearchResult = () => {
  request.page += 1;
  searchRequest(request);
};

const request = new ProductSearchRequest();

const search = async () => {
  const search = query.value;
  if (search.length < 1) {
    showSearchResult.value = false;
    return;
  }

  products.value = [];

  request.query = search;
  request.pageSize = 50;
  request.page = 1;
  request.includeAvailability = true;

  loadingSearch.value = true;
  showSearchResult.value = true;

  searchRequest(request);
  scrollSearchResultIntoView();
};

const searchRequest = async (request: ProductSearchRequest) => {
  try {
    const response = await productSearch(request);
    products.value = products.value.concat(response.products);
    totalHits.value = response.totalHits;
    await getCalculatedProductPrices();
  } finally {
    loadingSearch.value = false;
  }
};

const getCalculatedProductPrices = async () => {
  if (products.value.length === 0) {
    return;
  }

  try {
    const productIds = products.value.map((p) => p.id);
    const response = await getProductPrices(new ProductPricesRequest(productIds, props.customerId));

    products.value.forEach(async (product) => {
      const productPrices = response.find((p) => p.productId === product.id);

      if (!productPrices) {
        return;
      }

      const mainSupplier = product.supplierPrices.find((s) => s.isDefaultSupplier === true);
      if (mainSupplier) {
        mainSupplier.replacementCost = productPrices.costPrice;
      }

      const price = product.prices[0];
      if (!price) {
        return;
      }

      price.listPriceIncVat = productPrices.listPriceIncVat;
      price.listPrice = productPrices.listPrice;
      price.discountPercentage = productPrices.discountPercentage;
      price.discountedPrice =
        productPrices.discountedPrice != null ? productPrices.discountedPrice : productPrices.listPrice;
      price.contributionMargin = productPrices.contributionMargin;
    });
  } catch {
    toast.add({
      severity: "warn",
      summary: t("order.discount-error-summary"),
      detail: t("order.discount-error-detail"),
    });
  }
};

const selectProductSearchInput = () => {
  searchInput.value.$el.select();
};

useOrderShortcuts(OrderShortcutAction.SelectProductSearchInput, selectProductSearchInput);
useOrderShortcuts(OrderShortcutAction.SelectAdvancedSearch, () => (showAdvancedSearch.value = true));

const getProductStock = (product: SearchProductViewModel) => {
  return product.availability.reduce((accumulator: number, currentValue: ProductAvailability) => {
    return currentValue.quantityAvailableFromStock + accumulator;
  }, 0);
};

const getCostPrice = (product: SearchProductViewModel) => {
  const availability = product.availability.find((a) => a.productId === product.id);
  return availability?.costPrice ?? 0;
};

const selectRow = (searchProduct: SearchProductViewModel, index: number) => {
  editingRowIndex.value = index;
  if (searchProduct !== undefined) {
    selectedProductId.value = searchProduct.id;
    searchProduct.quantity = 1;
  }
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (editingRowIndex.value === event.index) return;

  editingRowIndex.value = null;
  selectedProductId.value = event.data.id;

  if (event.originalEvent != null) {
    if (!(event.originalEvent instanceof KeyboardEvent)) {
      return;
    }
    if (event.originalEvent.key === "Enter") {
      selectRow(event.data, event.index);
    }
  }
};

const onSelectProduct = (event: DataTableRowDoubleClickEvent) => {
  selectRow(event.data, event.index);
};

const openProductInfoDialog = (productId: string) => {
  if (productId !== undefined) {
    selectedProductId.value = productId;
    showDialog.value = true;
  }
};

const handleKeyPress = (event: KeyboardEvent) => {
  const elementBtnSave = document.getElementById("btn-save") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");
  const elementProductsSearch = tableProductsSearchResult.value.$el.querySelectorAll("tbody tr");
  const elementProductsSearchInput = tableProductsSearchResult.value.$el.querySelectorAll("td input");

  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    const focusedElement = document.activeElement;
    const rows = tableProductsSearchResult.value?.$el.querySelectorAll("tbody tr");

    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          focusedRowIndex.value = index;
          previouslyFocusedRow.value = tableProductsSearchResult.value.$el.querySelector(
            `tbody tr:nth-child(${index + 1})`,
          );

          previouslyFocusedRow.value.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          if (showMoreSearchResult.value && index > products.value.length - 5) {
            loadMoreSearchResult();
          }
        }
      });
    }
  }
  if (event.key.toLowerCase() === "p") {
    if (focusedRowIndex.value !== null && products.value.length > focusedRowIndex.value) {
      const selectedProduct = products.value[focusedRowIndex?.value];
      if (selectedProduct) {
        openProductInfoDialog(selectedProduct.id);
      }
    }
  }
  if (event.key === "Tab" && event.shiftKey === false) {
    if (elementProductsSearch.length < 0) {
      if (elementBtnSave?.disabled) {
        nextTick(() => elementBtnCancel?.focus());
      } else {
        nextTick(() => elementBtnSave?.focus());
      }
      event.preventDefault();
    } else {
      elementProductsSearchInput.forEach((input: HTMLInputElement, index: number) => {
        input.setAttribute("tabindex", (index + 1).toString());
        input.addEventListener("keydown", (event) => {
          event.preventDefault();
          const nextIndex = (index + 1) % elementProductsSearchInput.length;
          elementProductsSearchInput[nextIndex].focus();
        });
      });
    }
  }

  if (event.key === " " && event.ctrlKey === true) {
    loadMoreSearchResult();
  }
};

const tabKeydownInSearch = (event: Event) => {
  const elementBtnSave = document.getElementById("btn-save") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");
  if (products.value.length > 0) {
    focusSearchResult();
    event.preventDefault();
    return;
  } else {
    if (elementBtnSave?.disabled) {
      nextTick(() => elementBtnCancel?.focus());
    } else {
      nextTick(() => elementBtnSave?.focus());
    }
    event.preventDefault();
  }
};

const focusSearchResult = () => {
  if (products.value.length > 0) {
    const el = tableProductsSearchResult.value?.$el;
    previouslyFocusedRow.value = el.querySelector("tbody tr:first-of-type");
    if (el != null) {
      const firstItem = el.querySelector("tbody tr:first-of-type");
      if (firstItem) {
        firstItem.focus();

        firstItem.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }
};

const reset = () => {
  query.value = "";
  products.value = [];
  showAdvancedSearch.value = false;
};
defineExpose({ reset });

const minQuantity = (orderLine: OrderLine) => {
  const alreadyDeliveredOrInPicking = (orderLine.quantityDelivered ?? 0) + (orderLine.quantityAllocatedForPicking ?? 0);
  if (alreadyDeliveredOrInPicking > 0) {
    return alreadyDeliveredOrInPicking;
  }

  if (props.allowEditOrder) {
    return 1;
  }

  return 1;
};

const updateQuantity = (quantity: number, product: SearchProductViewModel) => {
  product.quantity = quantity;
};

const updatePriceExVat = (price: number, product: SearchProductViewModel) => {
  const orderLine = OrderLine.create(OrderProduct.createFromProduct(product), product);
  orderLine.price = price;
  calculatePriceForOrderLine(product, orderLine, CalculationBase.Price);
};

const updateDiscount = (value: number, product: SearchProductViewModel) => {
  const orderLine = OrderLine.create(OrderProduct.createFromProduct(product), product);
  orderLine.discountPercentage = value;
  calculatePriceForOrderLine(product, orderLine, CalculationBase.DiscountPercentage);
};

const updateContributionMargin = (value: number, product: SearchProductViewModel) => {
  const orderLine = OrderLine.create(OrderProduct.createFromProduct(product), product);
  orderLine.contributionMargin = value;
  calculatePriceForOrderLine(product, orderLine, CalculationBase.ContributionMargin);
};

const calculatePriceForOrderLine = (
  product: SearchProductViewModel,
  orderLine: OrderLine,
  calculationBase: CalculationBase,
) => {
  const response = calculateOrderLinePrice(orderLine, calculationBase);
  product.prices[0].discountedPrice = response.price;
  product.prices[0].contributionMargin = response.contributionMargin;
  product.prices[0].discountPercentage = response.discountPercentage;
};

const isUsed = (data: SearchProductViewModel, orderLines: OrderLine[]) => {
  const index = orderLines.findIndex((ol) => ol.product.id === data.id);
  return index > -1;
};

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value === false) {
      if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    }
  },
);

const openEllipsisContextMenu = (event: Event, data: SearchProductViewModel, index: number) => {
  focusedRow.value = data;
  focusedRowIndex.value = index;
  setFocusedRow(index);
  ellipsisContextMenuRef.value.openMenu(event);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  focusedRow.value = event.data;
  focusedRowIndex.value = event.index;
  setFocusedRow(event.index);
  ellipsisContextMenuRef.value.openMenu(event.originalEvent);
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = tableProductsSearchResult.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

const showMoreSearchResultTooltip = computed(() => {
  return { value: t("common.show-more-search-result-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style lang="scss" scoped>
.c-product-image {
  display: block;
  margin: auto;
  max-width: 2.3rem;
  max-height: 2.3rem;
}

:deep(.c-product-search-result).p-datatable .p-datatable-tbody > tr:focus {
  background-color: var(--p-blue-200); //--list-focus-bg: #CCE7F8;
  outline: none;
}

:deep(.p-inputnumber-input) {
  text-align: right;
}

:deep(.p-inputnumber.p-component.p-inputwrapper) {
  width: 100%;
}

:deep(.c-row-input.p-inputnumber-input) {
  --border-color: var(--p-blue-900); //--select-border: #003b66;
  font-size: 0.9rem;
  padding: 0.5rem;
  height: 1.8rem;
  margin-right: 1rem;
}

:deep(.c-product-search-result).p-datatable .p-datatable-tbody > tr {
  .c-product-search-select {
    display: none;
    color: var(--p-blue-900); // --action-btn-bg: #013A64;
    text-align: right;
  }

  .c-product-search-select-edit {
    display: inline;
    color: var(--p-blue-900); // --action-btn-bg: #013A64;
    text-align: center;
  }

  &:hover .c-product-search-select,
  &:focus .c-product-search-select {
    display: inline;
    .p-button-icon {
      color: var(--p-blue-900); // --action-btn-bg: #013A64;
    }
  }

  .c-product-search-used {
    color: var(--p-button-success-background); //--success-btn-bg: #539A4A;
    padding: 0.2rem 0.5rem;
  }

  &:hover .c-product-search-used,
  &:focus .c-product-search-used {
    display: none;
  }
}

.c-alternatives-button,
.c-accessory-button {
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  color: var(--p-blue-900); // --action-btn-bg: #013A64;
}

.c-inputgroup-search {
  width: 25vw;
  max-width: 27rem;
}

.c-fullscreen .c-inputgroup-search {
  width: 33.3333%;
}

.c-product-search-result {
  height: auto;
}

.c-fullscreen .c-product-search-result {
  border: none;
  padding: 0;
  margin-top: 0;
}

.c-order-view-button {
  overflow: visible;
}

.sticky {
  position: sticky;
  top: -1rem;
  bottom: 14.5rem;

  font-size: 20px;
  padding: 1rem 0.1rem;
  z-index: 1;

  @media (min-width: 992px) {
    top: 3rem;
    bottom: 12rem;
  }

  @media (min-width: 1574px) {
    bottom: 5.5rem;
  }

  &.c-fullscreen {
    padding: 1rem 0.1rem;
  }
}

.material-filled {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}

:deep(.c-inputgroup-search input.p-inputtext.p-component) {
  width: 25vw;
  max-width: 27rem;
  border-radius: 3px;
  background: #fff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
}
</style>

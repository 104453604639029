<template>
  <div class="flex justify-between flex-wrap items-center">
    <div>
      <span class="c-description">{{ t("order.order-number.label") }}: </span>
      <span data-testid="order-number">
        {{ !props.isNewOrder && props.orderNumber ? props.orderNumber : t("order.order-number.assigned") }}</span
      >
    </div>
    <div v-if="!props.isNewOrder && props.registered">
      <span class="c-description">{{ t("order.created") }}: </span>
      <span data-testid="registration-date"> {{ d(props.registered, "long") }}</span>
    </div>
    <div>
      <span class="c-description">{{ t("order.by") }}: </span>
      <span>{{ props.seller }}</span>
    </div>
    <div v-if="!props.isNewOrder && props.orderStatus">
      <Tag
        class="mr-2"
        data-testid="order-status"
        :value="t(`order.status.${props.orderStatus.toLowerCase()}`)"
        :severity="severityComputed"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";

const { t, d } = useI18n();

const props = defineProps<{
  isNewOrder: boolean;
  orderStatus: OrderStatus;
  orderNumber: number;
  registered: string;
  seller: string;
}>();

const severityComputed = computed<string>(() => {
  switch (props.orderStatus) {
    case OrderStatus.Delivered:
      return "success";
    case OrderStatus.Open:
      return "info";
    case OrderStatus.Picking:
    case OrderStatus.PartiallyDelivered:
      return "warning";
    default:
      return "";
  }
});
</script>

<style lang="scss" scoped>
.c-description {
  font-weight: 500;
  color: var(--text-color-normal);
}
</style>

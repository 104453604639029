import { NIL as emptyUuid } from "uuid";
import { ImageInfo } from "../image/ImageInfo";
import { Dimension } from "../search/product/Dimension";
import { ProductStatus } from "../search/product/ProductStatus";
import { Weight } from "../search/product/Weight";
import { ProductType } from "./ProductType";

export class Product {
  id = emptyUuid;
  name = "";
  productNumber = "";
  gtin = "";
  manufacturerId = "";
  productInfo = "";
  description = "";
  weight: Weight = new Weight();
  dimension: Dimension = new Dimension();
  productImages: ImageInfo[] = [];
  productStatus: ProductStatus = ProductStatus.Active;
  productType: ProductType = ProductType.Merchandise;
  productHierarchyId = "";
}

{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før",
    "nonEmptyGuid": "@:validations.required",
    "at-least-one": "Minst en {property} må være lagt til",
    "start-number-must-be-greater-than-last-used": "Startnummer må være større enn siste brukte ordrenummer"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "confirm": "Bekreft",
    "total-weight": "Total vekt (kg)",
    "total-volume": "Totalt volum (ccm)",
    "shipping-cost": "Fraktkostnad",
    "add-parcel": "Legg til pakke",
    "weight": "Vekt(kg)",
    "length": "Lengde (cm)",
    "width": "Bredde (cm)",
    "height": "Høyde (cm)",
    "shipping-dialog-header": "Kalkuler fraktkostnad",
    "calculate-freight": "Beregn frakt",
    "print": "Print",
    "add": "Ny Ordre",
    "update": "Oppdater",
    "save": "Lagre",
    "cancel": "Avbryt",
    "search": "Søk",
    "clear": "Fjern",
    "yes": "Ja",
    "no": "Nei",
    "delete": "Slett",
    "create": "Opprett",
    "use": "Bruk",
    "preview": "Forhåndsvisning",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "created": "Opprettet",
    "new-line": "Ny linje",
    "error": "Feil",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} ordre",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "delete-confirm": "Vil du slette linje no. {posNr}?",
    "save-tooltip": "Ingen endringer",
    "unit": {
      "pcs": "Stk"
    },
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Ikke funnet",
      "bad-request": "Feil i forespørsel",
      "error-occured": "En feil oppsto"
    }
  },
  "order": {
    "header": "Ordre",
    "no-result": "Ingen pakkseddel funnet",
    "created-date": "Dato",
    "customer-number": "Kundenr.",
    "customer-name": "Kundenavn",
    "freight-method-name": "Frakt",
    "quantity-of-order-items": "Ordrelinjer",
    "sum-of-total-ex-vat": "Ordre verdi",
    "pdf": "PDF",
    "printers": "Skrivere",
    "print-success": "Ordre sendt til skriver",
    "print-success-detail": "Ordre blir skrevet ut til {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix er ikke satt opp for dette firmaet. Gå hit for å sette opp {0}",
    "add-order": "Legg Til Ordre",
    "edit-order": "Endre Ordre",
    "search-order": "Søk Ordre",
    "created": "Opprettet",
    "by": "Av",
    "empty-list": "Bruk søk for å legge til produkter",
    "product-search": "Søk etter produkter",
    "no-products-found": "Ingen produkter funnet",
    "search-input-is-empty": "Søkefeltet er tomt, vennligst skriv inn et søkeord...",
    "order-line": "Ordrelinje",
    "download-link-missing": "Nedlastningslenke mangler",
    "discount-error-summary": "Kunne ikke hente rabattinformasjon",
    "discount-error-detail": "En feil oppstod under henting av rabattinformasjon.",
    "view-order": "Vis ordre",
    "search-mode": "Søkemodus",
    "hide-panel": "Skjul panel",
    "show-panel": "Vis panel",
    "context-menu": {
      "edit": "Endre",
      "delete": "Slett",
      "product-info": "Produktinformasjon"
    },
    "order-history": {
      "title": "Ordrehistorikk",
      "confirmation-line": "Ordrebekreftelse",
      "picking-list-line": "Plukkliste",
      "packing-note-line": "Pakkseddel",
      "invoice-line": "Faktura",
      "credit-note-line": "Kreditnota",
      "shipment-number": "Fraktnummer"
    },
    "properties": {
      "position-number": "Pos nr.",
      "main-image": "Bilde",
      "product-number": "Produktnummer",
      "product-name": "Produktnavn",
      "quantity": "Antall",
      "for-delivery": "Til levering",
      "back-order": "Rest",
      "back-order-date": "Restdato",
      "delivery-date": "Leveringsdato",
      "sales-unit": "Enhet",
      "price-excl-vat": "Pris",
      "price-incl-vat": "Pris ink.mva",
      "cost-price": "Kostpris",
      "contribution-margin": "DG%",
      "sum-excl-vat": "Sum eks.mva",
      "in-stock": "Tilgjengelig",
      "discounted-product-percentage": "Rabatt %",
      "discounted-product-price-ex-vat": "Pris eks.mva",
      "alternatives": "Alt",
      "accessory": "Til"
    },
    "search-filters": {
      "order-status": "Ordrestatus",
      "columns": "Kolonner"
    },
    "customer": {
      "no-customer": "Ingen kunde valgt",
      "header-business": "Bedrift",
      "label": "Kunde",
      "select": "Velg kunde",
      "no-search-result": "'{search}' ikke funnet",
      "empty-search-query": "Skriv inn kundens navn eller nummer for å søke",
      "loading": "Laster inn kunder, vennligst vent...",
      "search-footer-result": "Viser {first} til {last} av totalt {totalRecords} kunder",
      "number": "Kundenummer{symbol}",
      "name": "Navn",
      "city": "By",
      "email": "E-post{symbol}",
      "delivery-to": "Leveres til:",
      "phone": "Telefon{symbol}"
    },
    "document": {
      "open-customer-failed": "Kunne ikke åpne kunde",
      "no-customer-selected": "Ingen kunde er valgt. Velg en kunde og forsøk igjen."
    },
    "product": {
      "accessories": "Tilbehør",
      "alternatives": "Alternativ",
      "product": "Produkt",
      "open-product": "Åpne Produkt",
      "gtin": "GTIN(EAN)",
      "group-level": "Gruppe nivå:",
      "dimension-and-weight": "Dimensjoner og vekt:",
      "manufacturer": "Produsent",
      "description": "Beskrivelse",
      "name": "Produktnavn",
      "number": "Produktnummer",
      "type": "Produkttype",
      "unit": "Unit",
      "dimensions": {
        "label" : "Dimensjoner",
        "height": "Høyde",
        "width": "Bredde",
        "length": "Lengde",
        "weight": "Vekt"
      },
      "info-dialog-header": "Produktinformasjon",
      "no-image": "Ingen bilde funnet",
      "more-info": "Mer informasjon",
      "no-order-line-selected":  "Ingen ordrelinje er valgt. Velg en ordrelinje og forsøk igjen."
    },
    "order-reference": {
      "label": "Vår referanse",
      "placeholder": "Order referanse"
    },
    "contact": {
      "label": "Kontaktperson",
      "search-placeholder": "Søk etter kontaktpersoner eller skriv inn navn på en egendefinert kontakt",
      "no-contacts-found": "Ingen kontaktpersoner funnet for søket. Skriv inn navn på en egendefinert kontakt og trykk enter for å legge til.",
      "empty-search-query": "Søk etter kontaktpersoner eller skriv inn navn på en egendefinert kontakt",
      "firstname": "Fornavn",
      "lastname": "Etternavn",
      "phone": "Telefon",
      "email": "E-post",
      "default-contact": "Standard kontakt",
      "email-label": "E-post",
      "is-required": "Fornavn, etternavn eller e-post er påkrevd",
      "invalid-email": "Ugyldig epost"
    },
    "payment-term": "Betalingsbetingelse",
    "freight-method": "Fraktmåte",
    "delivery-option": {
      "label": "Levering",
      "total": "Samlet levering"
    },
    "comment": {
      "label": "Kommentar",
      "placeholder": "Legg inn kommentar..."
    },
    "add": {
      "title": "Opprett ordre",
      "toast": {
        "success": {
          "summary": "Oppretter ordre",
          "detail": "Ordre {orderNumber} er nå lagret"
        },
        "validation": {
          "summary": "Validering feilet",
          "detail": "Alle røde felter må rettes"
        }
      }
    },
    "edit": {
      "title": "Ordre informasjon",
      "toast": {
        "success": {
          "summary": "Ordre er oppdatert",
          "detail": "Endringene er straks klare"
        }
      }
    },
    "delete": {
      "confirm-message": "Vil du slette ordre?",
      "toast": {
        "success": {
          "summary": "Sletter ordre",
          "detail": "Ordre er snart slettet"
        }
      }
    },
    "toast-add-product-summary": "{quantity} produkt er lagt til",
    "order-number": {
      "label": "Ordrenummer",
      "assigned": "Blir tildelt når orderen blir opprettet"
    },
    "status": {
      "open": "Åpen",
      "delivered": "Sendt",
      "partiallydelivered": "Delvis levert",
      "picking": "Plukking",
      "processing": "Behandles"
    },
    "search": {
      "registered": "Registrert",
      "order-number": "Ordrenummer",
      "order-reference": "Referanse",
      "customer-number": "Kundenummer",
      "customer-name": "Kunde",
      "order-status": "Status",
      "total-hits": "Ingen ordre funnet | 1 ordre funnet | {totalHits} ordre funnet",
      "empty-list": "Ingen ordre funnet.",
      "toast": {
        "error": {
          "summary": "Kunne ikke laste inn ordre"
        },
        "open-order-failed": "Kunne ikke åpne ordre",
        "no-order-selected": "Ingen ordre er valgt. Velg et ordre og forsøk igjen."
      },
      "filter": "Filter",
      "filter-order-date": "Order dato",
      "filter-date-from": "Fra{symbol}",
      "filter-date-to": "Til{symbol}",
      "filter-last-ten-days": "Siste 10 dager",
      "filter-status": "Status",
      "filter-customer-number": "Kundenummer",
      "filter-customer-reference": "Kunde referance",
      "filter-product-number": "Produktnummer",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filter": "Tøm filter"
    },
    "footer": {
      "discount": "Rabatt:",
      "margin": "Margin:",
      "sum-ex-vat": "Sum eks. MVA:",
      "sum-inc-vat": "Sum inkl. MVA:",
      "freight": "Frakt(avtalt):",
      "freight-inc-vat": "Frakt inc. MVA:",
      "total": "Totalt ordre inkl. MVA:",
      "vat": "MVA:",
      "orderline": "Order linjer"
    },
    "address": {
      "dialog-header": "Endre leveringsinformasjon",
      "customer-name": "Kundenavn",
      "email": "Epost",
      "phone-number": "Telefonnummer",
      "lines": "Addresse",
      "country": "Land",
      "city": "By",
      "postal-code": "Postkode",
      "postal-code-city": "Postkode / by"
    },
    "complementary-items": {
      "accessories": "Tilbehør",
      "alternatives": "Alternativer",
      "header": {
        "available": "Tilgjengelig",
        "cost-price": "Kostpris",
        "contribution-margin": "DG%",
        "price-excl-vat": "Pris eks. mva",
        "price-incl-vat": "Pris inkl. mva"
      },
      "buttons": {
        "add": "Legg til"
      }
    },
    "availability-title": "Tilgjengelighet",
    "info-tabs": {
      "first-tab": "Kunde",
      "second-tab": "Leveringsadresse",
      "menu-change-delivery-address": "Endre leveringsadresse"
    },
    "shipping-price": "Fraktpris",
    "shipping-price-empty": "Ingen fast fraktpris"
  },
  "product-warehouse-availability": {
    "warehouse-name": "Lager",
    "quantity-in-stock": "Tilgjengelig",
    "quantity-available-from-stock": "Tilgj. lager",
    "quantity-in-orders": "Ordrereserve",
    "select-product": "Velg et produkt",
    "not-found": "Ingen lagerbeholdning funnet for dette produktet"
  },
  "product-purchase-quantity": {
    "purchase-order-no": "Innkjøp",
    "quantity": "Antall",
    "open": "Åpen",
    "cost-price": "Kostpris",
    "eta-date": "Ber. ankomst",
    "select-product": "Velg et produkt",
    "not-found": "Ingen innkjøp funnet for dette produktet"
  },
  "product-supplier-price": {
    "title": "Leverandører",
    "supplier-name": "Leverandør",
    "supplier-product-no": "Leverandør nummer",
    "product-cost-price": "Pris",
    "select-product": "Velg et produkt",
    "not-found": "Ingen leverandørpris funnet for dette produktet"
  },
  "settings": {
    "order-settings": "Ordre Settings",
    "order-number-series": "Ordrenummerserie",
    "current-setup": "Nåværende oppsett for gjeldende firma",
    "last-used-order-number": "Siste brukte ordrenummer",
    "currently-used-start-number": "Nåværende startnummer",
    "set-new-start-number": "Sett nytt startnummer",
    "set-new-start-number-success": "Nytt startnummer er satt",
    "no-order-number-found": "Ingen nummer serie funnet for dette firmaet"
  }
}

import { defineStore } from "pinia";
import { ref } from "vue";

export const useAccessoryStore = defineStore("accessoryStore", () => {
  const accessoryProductIds = ref<string[] | null>(null);
  const showAccessoryDialog = (ids: string[]) => {
    accessoryProductIds.value = ids;
  };
  const closeAccessoryDialog = () => {
    accessoryProductIds.value = null;
  };

  const alternativeProductIds = ref<string[] | null>(null);
  const showAlternativesDialog = (ids: string[]) => {
    alternativeProductIds.value = ids;
  };
  const closeAlternativesDialog = () => {
    alternativeProductIds.value = null;
  };

  return {
    showAccessoryDialog,
    closeAccessoryDialog,
    accessoryProductIds,
    showAlternativesDialog,
    closeAlternativesDialog,
    alternativeProductIds,
  };
});

import { OrderStatus } from "@/models/order/OrderStatus";

export class OrderSearchFilters {
  orderStatuses: OrderStatus[] = [];
  fromDate: string | null = null;
  toDate: string | null = null;
  productNumbers: string[] = [];
  productNames: string[] = [];
  customerNumbers: string[] = [];
  customerNames: string[] = [];
  orderReferences: string[] = [];

  public equals = (other: OrderSearchFilters): boolean => {
    return (
      this.orderStatuses === other.orderStatuses &&
      this.fromDate === other.fromDate &&
      this.toDate === other.toDate &&
      this.productNumbers === other.productNumbers &&
      this.productNames === other.productNames &&
      this.customerNumbers === other.customerNumbers &&
      this.customerNames === other.customerNames &&
      this.orderReferences === other.orderReferences
    );
  };
}

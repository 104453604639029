import { ProductSearchFilters } from "./ProductSearchFilters";

export class ProductSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  filters = new ProductSearchFilters();
  includeAvailability = false;

  constructor(query: string, pageSize = 25, includeAvailability = false) {
    this.query = query;
    this.pageSize = pageSize;
    this.includeAvailability = includeAvailability;
  }

  public equals = (other: ProductSearchRequest): boolean => {
    return (
      this.query === other.query &&
      this.page === other.page &&
      this.pageSize === other.pageSize &&
      this.filters.equals(other.filters) &&
      this.includeAvailability === other.includeAvailability
    );
  };
}

import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ProductPrices } from "@/models/discount/ProductPrices";

class DiscountApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_CATALOG != "undefined" && import.meta.env.VITE_APP_API_CATALOG
        ? import.meta.env.VITE_APP_API_CATALOG
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async productPrices(
    authHeaders: AuthHeaders,
    productIds: string[],
    customerId: string | null = null,
  ): Promise<ProductPrices[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/prices/product-prices", {
        params: {
          productIds: productIds,
          customerId: customerId,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }) => data);
  }
}

const discountApi = new DiscountApi();
export { discountApi };

import { Address } from "./Address";
import { Delivery } from "./Delivery";
import { OrderCustomer } from "./OrderCustomer";
import { DeliveryOption } from "./DeliveryOption";
import { OrderLine } from "./OrderLine";
import { NIL as emptyUuid } from "uuid";
import { OrderFreightMethod } from "./OrderFreightMethod";
import { OrderPaymentTerm } from "./OrderPaymentTerm";
import { Order } from "./Order";

export class NewOrder {
  customer = new OrderCustomer();
  delivery = new Delivery();
  invoiceAddress = new Address();
  orderLines: OrderLine[] = [];
  orderReference = "";
  seller = "";
  deliveryOption = DeliveryOption.Total;
  comment = "";
  paymentTerm = new OrderPaymentTerm(emptyUuid, "", 0);
  freightMethod = new OrderFreightMethod();
  shippingPrice = 0;
  agreedFreight = false;

  public static createFromOrder(order: Order): NewOrder {
    const newOrder = new NewOrder();
    newOrder.customer = order.customer;
    newOrder.delivery = order.delivery;
    newOrder.invoiceAddress = order.invoiceAddress;
    newOrder.orderLines = order.orderLines;
    newOrder.orderReference = order.orderReference;
    newOrder.seller = order.seller;
    newOrder.deliveryOption = order.deliveryOption;
    newOrder.comment = order.comment;
    newOrder.paymentTerm = order.paymentTerm;
    newOrder.freightMethod = order.freightMethod;
    newOrder.shippingPrice = order.shippingPrice;
    newOrder.agreedFreight = order.agreedFreight;
    return newOrder;
  }
}

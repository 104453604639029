<template>
  <Popover ref="op" data-testid="order-sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in selectedColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border dark:bg-primary-400/10"
            @click="emit('onSelectedRow', column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumns"
    :columns="props.filteredColumns"
    :label="t('common.reset')"
    @resetColumns="emit('resetColumns')"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { DataTableColumn, DataTableColumnExt } from "@cumulus/components";
import { useI18n } from "vue-i18n";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";

const { t } = useI18n();

const props = defineProps<{
  filteredColumns: DataTableColumn[];
}>();

const defaultSortField = defineModel<DataTableColumn | undefined>("defaultSortField", { required: true });
const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });

const selectedColumns = defineModel<DataTableColumn[] | DataTableColumnExt[]>("selectedColumns");

const emit = defineEmits<{
  (e: "onSelectedRow", value: DataTableColumn): void;
  (e: "resetColumns", value: void): void;
}>();

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const op = ref();
const openSortList = (event: Event) => {
  op.value.toggle(event);
};

defineExpose({
  openColumnChooser,
  openSortList,
});
</script>

<style scoped lang="scss"></style>

import { useAuth } from "@cumulus/event-bus";
import { bringApi } from "./BringApi";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";
import { useErrorHandler } from "@/api/ErrorHandler";

export function useBring() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getBringShippingGuide = async (request: BringShippingGuideRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await bringApi.getBringShippingGuide(authHeaders, request);
    } catch (error) {
      await handleError(error);
    }
  };

  return { getBringShippingGuide };
}

import { CustomerType } from "@/models/customer/CustomerType";
import { SearchOrderCustomerContact } from "./SearchOrderCustomerContact";

export class SearchOrderCustomer {
  id = "";
  customerName = "";
  customerNumber = "";
  customerType = CustomerType.B2C;
  contact = new SearchOrderCustomerContact();
}

<template>
  <CumulusRightSidebar v-model:showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("order.search.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h2 class="text-center">{{ t("order.search.filter-order-date") }}</h2>
      <div class="formgrid grid grid-cols-12 gap-4">
        <div class="col-span-6 text-center mb-4">
          <CumulusDatePicker
            v-model:date="fromDateComputed"
            :label="t('order.search.filter-date-from', { symbol: ':' })"
            :dataTestId="'from-date-filter'"
            :debounceTime="400"
          />
        </div>
        <div class="col-span-6 text-center mb-4">
          <CumulusDatePicker
            v-model:date="toDateComputed"
            :label="t('order.search.filter-date-to', { symbol: ':' })"
            :dataTestId="'to-date-filter'"
            :debounceTime="400"
          />
        </div>
      </div>

      <PrimeButton
        @click="setDateFilterLastDays(10)"
        :label="t('order.search.filter-last-ten-days')"
        class="w-full"
        data-testid="last-10-days-filter-btn"
      />

      <hr class="c-seperator-line" />

      <h2 class="text-center">{{ t("order.search.filter-status") }}</h2>
      <div
        v-for="(value, index) in allStatusesComputed"
        :key="index"
        class="flex justify-between relative items-center p-2 rounded-md"
        :class="{
          'c-list-row-bg mt-1': index % 2 === 0,
          'c-list-alternate-row-bg mt-1': index % 2 === 1,
        }"
      >
        <label :for="'order-status-' + value.status.toLowerCase()">{{
          t(`order.status.${value.status.toLowerCase()}`)
        }}</label>
        <div class="ml-auto">
          <Checkbox
            v-model="statusesComputed"
            :value="value.status"
            :inputId="'order-status-' + value.status.toLowerCase()"
            :data-testid="'order-status-' + value.status.toLowerCase()"
          />
        </div>
      </div>

      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="customer-number-filter"> {{ t("order.customer.label") }} </label>
      </h2>

      <AutoCompletePrime
        class="c-customer-numbers"
        inputId="customer-number-filter"
        data-testid="customer-number-filter"
        :placeholder="props.filters.customerNumbers.length > 0 ? '' : t('order.search.filter-customer-number')"
        multiple
        fluid
        :typeahead="false"
        v-model="customerNumbersComputed"
      />

      <AutoCompletePrime
        class="c-customer-name mt-2"
        inputId="customer-name-filter"
        data-testid="customer-name-filter"
        :placeholder="props.filters.customerNames.length > 0 ? '' : t('order.customer-name')"
        multiple
        fluid
        :typeahead="false"
        v-model="customerNamesComputed"
      />

      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="product-number-filter">
          {{ t("order.product.product") }}
        </label>
      </h2>

      <AutoCompletePrime
        class="c-product-numbers"
        data-testid="product-number-filter"
        inputId="product-number-filter"
        :placeholder="props.filters.productNumbers.length > 0 ? '' : t('order.properties.product-number')"
        multiple
        fluid
        :typeahead="false"
        v-model="productNumbersComputed"
      />

      <AutoCompletePrime
        class="c-product-names mt-2"
        data-testid="product-name-filter"
        inputId="product-name-filter"
        :placeholder="props.filters.productNames.length > 0 ? '' : t('order.properties.product-name')"
        multiple
        fluid
        :typeahead="false"
        v-model="productNamesComputed"
      />

      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="product-number-filter"> {{ t("order.header") }} </label>
      </h2>

      <AutoCompletePrime
        class="c-order-ref mt-2"
        data-testid="order-ref-filter"
        inputId="order-ref-filter"
        :placeholder="props.filters.orderReferences.length > 0 ? '' : t('order.order-reference.label')"
        multiple
        fluid
        :typeahead="false"
        v-model="orderReferencesComputed"
      />
    </template>
  </CumulusRightSidebar>
</template>

<script setup lang="ts">
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusRightSidebar, CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: OrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: OrderSearchFilters): void;
}>();

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(OrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<OrderStatus[]>({
  get: () => props.filters.orderStatuses,
  set: (value) => {
    const filters = { ...props.filters, orderStatuses: value };
    emit("update:filters", filters);
  },
});

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    toDate = toDate.replace("T00:00:00.000Z", "T23:59:59.999Z");
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const setDateFilterLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);

  const filters = {
    ...props.filters,
    toDate: toDate.toJSON(),
    fromDate: fromDate.toJSON(),
  };
  emit("update:filters", filters);
};

const customerNumbersComputed = computed<string[]>({
  get: () => props.filters.customerNumbers,
  set: (value) => {
    const filters = { ...props.filters, customerNumbers: value };
    emit("update:filters", filters);
  },
});

const customerNamesComputed = computed<string[]>({
  get: () => props.filters.customerNames,
  set: (value) => {
    const filters = { ...props.filters, customerNames: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const productNamesComputed = computed<string[]>({
  get: () => props.filters.productNames,
  set: (value) => {
    const filters = { ...props.filters, productNames: value };
    emit("update:filters", filters);
  },
});

const orderReferencesComputed = computed<string[]>({
  get: () => props.filters.orderReferences,
  set: (value) => {
    const filters = { ...props.filters, orderReferences: value };
    emit("update:filters", filters);
  },
});
</script>

<style scoped lang="scss">
h2,
label {
  font-family: var(--font-family);
}
h2 {
  font-size: 14px;
}
.c-seperator-line {
  border: 1px solid var(--seperator-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.centered-child {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
.c-filter-header-container {
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0rem;
  background-color: #fff;
  z-index: 1;
  padding: 0.5rem;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.c-filter-header-container i {
  margin-right: 0.5rem;
}
.c-customer-numbers,
.c-customer-name {
  width: 100%;
}
.c-customer-numbers :deep(ul),
.c-customer-name :deep(ul) {
  width: 100%;
}
.c-product-numbers,
.c-product-names,
.c-order-ref {
  width: 100%;
}
.c-product-numbers :deep(ul),
.c-product-names :deep(ul),
.c-order-ref :deep(ul) {
  width: 100%;
}
</style>

export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  css: ({ dt }) => `      
      .c-input-minimal .p-autocomplete-dropdown {
        border-top: none !important;
        border-right: none !important;
        background: transparent !important;
        color: var(--p-form-field-icon-color) !important;
      }

      .c-input-filled .p-autocomplete-dropdown {
        background: ${dt("color-mix(in srgb, {primary.50}, transparent 50%)")} !important;
        color: var(--p-form-field-icon-color) !important;
      }    
      .dark .c-input-filled .p-autocomplete-dropdown {
        background: ${dt("color-mix(in srgb, {form.field.background}, transparent 50%)")} !important;            
      }
      `,
};

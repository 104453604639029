import { onMounted, onUnmounted } from "vue";
import { OrderShortcutAction } from "./OrderShortcutAction";

export const useOrderShortcuts = (action: OrderShortcutAction, callback: () => void) => {
  const handleShortcuts = (e: KeyboardEvent) => {
    switch (action) {
      case OrderShortcutAction.SelectProductSearchInput:
        if (e.altKey && e.key === "s") {
          e.preventDefault();
          callback();
        }
        break;
      case OrderShortcutAction.SelectAdvancedSearch:
        if (e.altKey && e.key === "a") {
          e.preventDefault();
          callback();
        }
        break;
      default:
        break;
    }
  };
  onMounted(() => {
    window.addEventListener("keydown", handleShortcuts);
  });
  onUnmounted(() => {
    window.removeEventListener("keydown", handleShortcuts);
  });
};

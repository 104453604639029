import { useAuth } from "@cumulus/event-bus";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { PickingList } from "@/models/warehouse/PickingList";
import type { PackingNote } from "@/models/warehouse/PackingNote";
import { ProductAvailability } from "@/models/warehouse/ProductAvailability";
import { warehouseApi } from "./WarehouseApi";
import { useErrorHandler } from "../ErrorHandler";

export function useWarehouse() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getWarehouse = async (id: string): Promise<Warehouse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllWarehouses = async (): Promise<Warehouse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPickingLists = async (orderId: string): Promise<PickingList[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getPickingList(authHeaders, orderId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPackingNotes = async (orderId: string): Promise<PackingNote[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getPackingNote(authHeaders, orderId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getProductAvailability = async (productId: string): Promise<ProductAvailability[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await warehouseApi.getProductAvailability(authHeaders, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllWarehouses,
    getWarehouse,
    getPickingLists,
    getPackingNotes,
    getProductAvailability,
  };
}

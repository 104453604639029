<template>
  <div class="field my-6">
    <DataTable
      dataKey="id"
      :value="productPurchaseQuantityList"
      responsiveLayout="scroll"
      selectionMode="single"
      data-testid="product-purchase-quantity-table"
      class="c-quantity-table c-datatable"
      striped-rows
      scrollable
      scrollHeight="12rem"
      :pt="{
        bodyrow: 'bg-slate-50 dark:bg-primary-400/10 text-base',
        bodyCell: 'p-0 m-0',
      }"
    >
      <Column field="purchaseOrderNumber" :header="t('product-purchase-quantity.purchase-order-no')" class="max-w-32">
        <template #body="{ data, field, index }">
          <div class="text-ellipsis whitespace-nowrap overflow-hidden" :data-testid="`purchase-order-no-${index}`">
            {{ data[field as keyof typeof data] }}
          </div>
        </template>
      </Column>
      <Column field="quantity" :header="t('product-purchase-quantity.quantity')">
        <template #body="{ data, field, index }">
          <div class="flex justify-end" :data-testid="`quantity-${index}`">
            {{ n(data[field as keyof typeof data], "decimal") }}
          </div>
        </template>
      </Column>

      <Column field="estimatedArrivalDate" :header="t('product-purchase-quantity.eta-date')" class="max-w-32">
        <template #body="{ data, field, index }">
          <div class="flex justify-end">
            <input
              type="date"
              :value="dateFormatted(data[field as keyof typeof data])"
              :data-testid="`eta-date-${index}`"
              class="c-input-date"
              disabled
            />
          </div>
        </template>
      </Column>

      <template #empty>
        <div class="flex justify-start">
          {{
            t(productId == null ? "product-purchase-quantity.select-product" : "product-purchase-quantity.not-found")
          }}
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ProductPurchaseQuantity } from "@/models/purchase/ProductPurchaseQuantity";
import { usePurchase } from "@/api/purchase/PurchaseService";
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

const props = defineProps<{
  productId: string | null;
}>();

const { getProductPurchaseQuantity } = usePurchase();

const productPurchaseQuantityList = ref<ProductPurchaseQuantity[]>();

watch(
  () => props.productId,
  async (newValue) => await fetchAvailability(newValue),
);

onMounted(async () => await fetchAvailability(props.productId));

const fetchAvailability = async (productId: string | null) => {
  if (productId == null) {
    productPurchaseQuantityList.value = [];
    return;
  }

  const result = await getProductPurchaseQuantity(productId);
  productPurchaseQuantityList.value = result;
};

const dateFormatted = (value: string | null | undefined): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();
  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;
  return yyyy + "-" + mm + "-" + dd;
};
</script>

<style scoped lang="scss">
.c-datatable.p-datatable :deep(.p-datatable-tbody > tr > td) {
  padding: 0.2rem 0.3rem;
}

.c-input-date {
  border: none;

  &:disabled {
    background-color: transparent;
    color: var(--text-color);
    text-align: right;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: normal;
  }
}
</style>

export class Parcel {
  constructor(parcelNumber: number, weight: number = 0) {
    this.parcelNumber = parcelNumber;
    this.weight = weight;
  }
  id = "";
  parcelNumber = 1;
  content = "";
  weight = 0;
  width = 0;
  length = 0;
  height = 0;
}

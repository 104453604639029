export class ProductPricesRequest {
  productIds: string[] = [];
  customerId: string | null = null;

  constructor(productIds: string[], customerId: string | null = null) {
    this.productIds = productIds;
    this.customerId = customerId;
  }

  public equals = (other: ProductPricesRequest): boolean => {
    return this.productIds.toString() === other.productIds.toString() && this.customerId === other.customerId;
  };
}

<template>
  <FloatLabel variant="on">
    <InputText
      id="customer-search-input"
      v-model="customer.customerName"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('order.customer.select').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-contact-dropdown"
      :class="{ 'p-invalid': val.customer.$error, 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      data-testId="customer-search-input"
      :pt:overlay:class="'hidden'"
      :fluid="true"
      :isRequired="true"
      @click="showCustomerPanel"
    />
    <label for="customer-search-input" class="c-required">{{ t("order.customer.select") }}</label>
  </FloatLabel>
  <small v-if="val.$error" class="ml-6 p-error" data-testid="customer-search-input-error">
    {{ val.$errors[0].$message }}
  </small>

  <CustomerPanel ref="customerPanelRef" :customer="customer" @selectedCustomerId="selectedCustomerId" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import CustomerPanel from "./CustomerPanel.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderLine } from "@/models/order/OrderLine";

const { t } = useI18n();

const props = defineProps<{
  focusSearchInput: boolean;
  orderStatus?: OrderStatus;
  orderLines: OrderLine[];
}>();

const customer = defineModel<OrderCustomer>("customer", { required: true });

const emit = defineEmits<{
  (e: "update:focusSearchInput", value: boolean): void;
  (e: "selectedCustomerId", value: string): void;
}>();

const focusSearchInputComputed = computed<boolean>({
  get: () => props.focusSearchInput,
  set: (value) => emit("update:focusSearchInput", value),
});

const selectedCustomerId = (id: string) => {
  focusSearchInputComputed.value = false;
  emit("selectedCustomerId", id);
};

const customerPanelRef = ref();
const showCustomerPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  customerPanelRef.value.toggle(event);
};

const rules = {
  customer: {
    customerName: {
      required,
    },
  },
};

const val = useVuelidate(rules, { customer });

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-customer-dropdown {
  width: 100%;
}
</style>

<template>
  <div class="mt-4">
    <FloatLabelInput
      :label="t('order.order-reference.label')"
      v-model:value="orderReference"
      id="order-reference"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('order.order-reference.label').toLowerCase() })"
      :dataTestId="'order-reference'"
      :disabled="!props.allowEditOrder"
      autocomplete="off"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{ allowEditOrder: boolean }>();
const orderReference = defineModel<string>("orderReference");
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>

import { Customer } from "@/models/customer/Customer";
import { Address } from "./Address";

export class Delivery {
  customerName = "";
  phoneNumber = "";
  email = "";
  address = new Address();

  public static createFromCustomer(customer: Customer): Delivery {
    const delivery = new Delivery();
    delivery.customerName = customer.name;
    delivery.phoneNumber = customer.phoneNumber;
    delivery.email = customer.email;

    const address = new Address();
    address.addressLines = customer.deliveryAddress.addressLines;
    address.city = customer.deliveryAddress.city;
    address.countryIso = customer.deliveryAddress.countryIso;
    address.postalCode = customer.deliveryAddress.postalCode;
    delivery.address = address;
    return delivery;
  }
}

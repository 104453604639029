import { ProductSearchFilters } from "./ProductSearchFilters";

export class ProductSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  filters = new ProductSearchFilters();
  includeAvailability = false;

  public static equals = (obj1: ProductSearchRequest | null, obj2: ProductSearchRequest | null): boolean => {
    if (obj1 === null || obj2 === null) {
      return false;
    }

    return (
      obj1.query === obj2.query &&
      obj1.page === obj2.page &&
      obj1.pageSize === obj2.pageSize &&
      ProductSearchFilters.equals(obj1.filters, obj2.filters) &&
      obj1.includeAvailability === obj2.includeAvailability
    );
  };

  public static clone = (obj: ProductSearchRequest | null): ProductSearchRequest | null => {
    if (obj === null) {
      return null;
    }

    const clone = new ProductSearchRequest();
    clone.query = obj.query;
    clone.page = obj.page;
    clone.pageSize = obj.pageSize;
    clone.filters = ProductSearchFilters.clone(obj.filters);
    clone.includeAvailability = obj.includeAvailability;

    return clone;
  };
}

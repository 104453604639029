import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { ProductSearchResponseViewModel } from "@/models/search/product/view-model/ProductSearchResponseViewModel";
import { useErrorHandler } from "@/api/ErrorHandler";
import { ProductSearchByIdsRequest } from "@/models/search/product/ProductSearchByIdsRequest";

export const useProductSearchService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const loading = ref(false);

  let productRequestCache: ProductSearchRequest | null = null;
  let productResponseCache: ProductSearchResponseViewModel | null = null;

  const productSearch = async (
    request: ProductSearchRequest,
    clearCache = false,
  ): Promise<ProductSearchResponseViewModel> => {
    try {
      loading.value = true;
      if (clearCache) {
        productRequestCache = null;
        productResponseCache = null;
      }

      const authHeaders = await getAuthHeaders();

      if (productRequestCache && productRequestCache?.equals(request) && productResponseCache !== null) {
        return productResponseCache;
      }
      productResponseCache = (await searchApi.productSearch(authHeaders, request)) as ProductSearchResponseViewModel;
      productRequestCache = request;
      return productResponseCache;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }

    return {
      products: [],
      totalHits: 0,
    } as ProductSearchResponseViewModel;
  };

  let productByIdsRequestCache: ProductSearchByIdsRequest | null = null;
  let productByIdsResponseCache: ProductSearchResponseViewModel | null = null;

  const productSearchByIds = async (
    request: ProductSearchByIdsRequest,
    clearCache = false,
  ): Promise<ProductSearchResponseViewModel> => {
    try {
      loading.value = true;
      if (clearCache) {
        productByIdsRequestCache = null;
        productByIdsResponseCache = null;
      }

      const authHeaders = await getAuthHeaders();

      if (productByIdsRequestCache && productByIdsRequestCache?.equals(request) && productByIdsResponseCache !== null) {
        return productByIdsResponseCache;
      }

      productByIdsResponseCache = (await searchApi.productSearchByIds(
        authHeaders,
        request,
      )) as ProductSearchResponseViewModel;
      productByIdsRequestCache = request;
      return productByIdsResponseCache;
    } catch (error) {
      await handleError(error);
    }

    return {
      products: [],
      totalHits: 0,
    } as ProductSearchResponseViewModel;
  };

  return { loading, productSearch, productSearchByIds };
};

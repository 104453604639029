import { Address as CustomerAddress } from "@/models/customer/Address";

export class Address {
  addressLines: string[] = [];
  countryIso = "";
  postalCode = "";
  city = "";

  public static createFromCustomer(customerAddress: CustomerAddress): Address {
    const address = new Address();
    address.addressLines = customerAddress.addressLines;
    address.city = customerAddress.city;
    address.countryIso = customerAddress.countryIso;
    address.postalCode = customerAddress.postalCode;
    return address;
  }
}

import { httpClient, AuthHeaders } from "@cumulus/http";
import { ProductPrices } from "@/models/discount/ProductPrices";

class DiscountApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != "undefined" && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async productPrices(
    authHeaders: AuthHeaders,
    productIds: string[],
    customerId: string | null = null,
  ): Promise<ProductPrices[]> {
    let query = productIds.map((productId) => `productIds=${productId}`).join("&");

    if (customerId !== null) {
      query += `&customerId=${customerId}`;
    }

    return await httpClient(this.uri, authHeaders)
      .get("/products/prices?" + query)
      .then(({ data }) => data);
  }
}

const discountApi = new DiscountApi();
export { discountApi };

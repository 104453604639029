export const OrderLineColumns = {
  ReorderHandle: "reorderHandle",
  PositionNumber: "positionNumber",
  ProductImages: "product.productImages",
  ProductNumber: "product.productNumber",
  ProductName: "product.name",
  Description: "product.description",
  Product: "product.product",
  Quantity: "quantity",
  QuantityForDelivery: "quantityForDelivery",
  QuantityDelivered: "quantityDelivered",
  QuantityInBackOrder: "quantityInBackOrder",
  BackOrderDate: "backOrderDate",
  ShippingDate: "shippingDate",
  FifoDate: "fifoDate",
  Price: "price",
  PriceIncVat: "priceIncVat",
  CostPrice: "costPrice",
  DiscountPercentage: "discountPercentage",
  ContributionMargin: "contributionMargin",
  SumLine: "sumLine",
  EditHandle: "editHandle",
} as const;

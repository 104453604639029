import { httpClient, type AuthHeaders } from "@cumulus/http";
import { NewOrder } from "@/models/order/NewOrder";
import { Order } from "@/models/order/Order";

class OrderApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != "undefined" && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER + "/orders"
        : `${import.meta.env.VITE_APP_API_URL as string}/orders`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Order> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }

  public async create(authHeaders: AuthHeaders, order: NewOrder): Promise<number> {
    return await httpClient(this.uri, authHeaders)
      .post("", order)
      .then((response) => response.data);
  }

  public async update(authHeaders: AuthHeaders, order: Order): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("", order);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}
const orderApi = new OrderApi();
export { orderApi };

import { httpClient, type AuthHeaders } from "@cumulus/http";
import type { CreditNote } from "@/models/finance/CreditNote";

class CreditNoteApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != (undefined as unknown) && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/credit-notes"
        : `${import.meta.env.VITE_APP_API_URL as string}/credit-notes`;
  }

  public async list(authHeaders: AuthHeaders, orderId: string): Promise<CreditNote[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`?orderId=${orderId}`)
      .then(({ data }) => data);
  }
}

export const creditNoteApi = new CreditNoteApi();

import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { FreightMethod } from "@/models/freight-method/FreightMethod";
import { freightMethodApi } from "./FreightMethodApi";

export function useFreightMethod() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getFreightMethodsForCustomers = async (): Promise<FreightMethod[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await freightMethodApi.getFreightMethodsForCustomers(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getFreightMethodsForCustomers };
}

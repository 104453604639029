export const OrderSearchColumns = {
  Registered: "registered",
  OrderNumber: "ordernumber",
  OrderReference: "orderReference",
  CustomerName: "customer.customerName",
  CustomerNumber: "customer.customerNumber",
  OrderStatus: "orderStatus",
  PDF: "pdf",
  EditHandler: "editHandler",
} as const;

import { ref, onMounted, onUnmounted } from "vue";

export function useMobile() {
  const isMobile = ref(false);

  const checkScreen = () => {
    const isBrowser = typeof window !== "undefined";
    const windowWidth = isBrowser ? window.innerWidth : 0;

    if (windowWidth < 992) {
      isMobile.value = true;
      return;
    }
    isMobile.value = false;
  };

  onMounted(() => {
    checkScreen();
    window.addEventListener("resize", checkScreen);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", checkScreen);
  });

  return { isMobile };
}

<template>
  <div class="mt-6">
    <div v-for="error in props.errors" :key="error.code">
      <div>{{ error.code }}</div>
      <div>{{ error.description }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Error as BringError } from "@/models/bring/BringShippingGuideResponse";
const props = defineProps<{
  errors: BringError[];
}>();
</script>

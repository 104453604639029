<template>
  <DataTable
    ref="customerTableRef"
    :value="customers"
    scrollHeight="flex"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :loading="loading"
    :rowHover="true"
    data-testid="customer-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :lazy="true"
    :paginator="true"
    :rows="pageSize"
    :totalRecords="totalHits"
    @sort="onSort"
    @page="onPage"
    class="c-datatable"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :currentPageReportTemplate="
      t('order.customer.search-footer-result', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search absolute mt-2" />
        <InputText
          id="customerSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('common.search')"
          @focus="selectAllOnFocus"
          @keyup.enter="searchInputEnterKey"
          @keypress.tab="focusManufactureTable"
          @keydown.down="focusManufactureTable"
          class="w-full pl-8"
          autocomplete="off"
          data-testid="customer-search-list-input"
        />
      </span>
    </template>

    <template>
      <Column :header="t('order.customer.header-business')" class="c-col-image">
        <template #body="{ data }">
          <i class="pi" :class="data.customerType === CustomerType.B2B ? 'true-icon pi-check-circle' : ''"></i>
        </template>
      </Column>

      <Column field="customerNumber" :header="t('order.customer.number')"></Column>
      <Column field="name" :header="t('order.customer.name')"></Column>
      <Column field="deliveryAddress.city" :header="t('order.customer.city')"></Column>
      <Column field="phoneNumber" :header="t('order.customer.phone')"></Column>
      <Column field="email" :header="t('order.customer.email')"></Column>
    </template>

    <template #loading>{{ t("order.customer.loading") }}</template>

    <template #empty>
      <div>
        {{
          query.length < 1
            ? t("order.customer.empty-search-query")
            : t("order.customer.no-search-result", { search: query })
        }}
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { DataTablePageEvent, DataTableRowSelectEvent, DataTableSortEvent } from "primevue/datatable";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { CustomerType } from "@/models/customer/CustomerType";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";
import { useCustomerSearchService } from "@/api/search/CustomerSearchService";

const { t } = useI18n();

const emit = defineEmits<{
  (e: "selectedCustomerId", value: string): void;
}>();

const pageSize = ref<number>(10);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const customerTableRef = ref();

const focusManufactureTable = (event: Event) => {
  event.stopPropagation();
  customerTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();

  focusManufactureTable(event);
};

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  emit("selectedCustomerId", (event.data as OrderCustomer).id);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const { customerSearch } = useCustomerSearchService();

const loading = ref(false);
const totalHits = ref(0);
const query = ref<string>("");

const onPage = async (event: DataTablePageEvent) => {
  nextTick(async () => {
    page.value = event.page + 1;
    pageSize.value = event.rows;
    await search(query.value);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let field = "";
  nextTick(async () => {
    if (typeof event.sortField === "string") {
      field = event.sortField;
    }

    sortField.value = field;
    sortOrder.value = event.sortOrder === 1 ? 1 : -1;
    await search(query.value);
  });
};

const customers = ref<SearchCustomer[]>([]);
const search = async (q: string) => {
  if (q.length < 1) {
    customers.value = [];
    totalHits.value = 0;
    return;
  }
  query.value = q.length < 1 ? "*" : q;

  try {
    loading.value = true;
    const request = new CustomerSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    const response = await customerSearch(request);

    customers.value = response.customers;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss">
.customer-panel {
  .c-no-search-result {
    color: var(--pink-700);
  }

  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: 2px solid var(--p-focus-ring-color); //--floating-input-border-on-focus => 2px solid var(--active-component-color); =>  --active-component-color: #2680EB;
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--p-focus-ring-color); //var(--active-component-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--p-focus-ring-color); //var(--active-component-color);
      font-weight: bolder;
    }
  }

  .p-datatable-footer {
    padding: 0.75rem 0;
    float: right;
  }
}
</style>

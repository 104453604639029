import { CustomerType } from "@/models/customer/CustomerType";
import { OrderContact } from "./OrderContact";
import { NIL as emptyUuid } from "uuid";
import { Customer } from "@/models/customer/Customer";

export class OrderCustomer {
  id = emptyUuid;
  customerNumber = "";
  customerType = CustomerType.B2C;
  customerName = "";
  organizationNumber = "";
  phoneNumber = "";
  email = "";
  currencyIso = "";
  creditLimit = 0;
  contact = new OrderContact();
  documentLanguageIso = "";

  public static createFromCustomer(customer: Customer): OrderCustomer {
    const orderCustomer = new OrderCustomer();
    orderCustomer.id = customer.id;
    orderCustomer.customerNumber = customer.customerNumber;
    orderCustomer.customerType = customer.customerType;
    orderCustomer.customerName = customer.name;
    orderCustomer.organizationNumber = customer.businessCustomer?.organizationNumber || "";
    orderCustomer.phoneNumber = customer.phoneNumber;
    orderCustomer.email = customer.email;
    orderCustomer.currencyIso = customer.payment.currencyIso;
    orderCustomer.creditLimit = customer.payment.creditLimit;
    orderCustomer.documentLanguageIso = customer.documentLanguageIso;

    orderCustomer.contact = OrderCustomer.createOrderContact(customer);
    return orderCustomer;
  }

  private static createOrderContact = (customer: Customer): OrderContact => {
    if (customer.customerType == CustomerType.B2B) {
      const defaultContact = customer.businessCustomer.contacts[0]; //TODO: add default customer => .find((contact) => contact.isDefaultContact);
      if (defaultContact) {
        return OrderContact.createFromCustomerContact(defaultContact);
      }
      return new OrderContact();
    } else {
      const orderConstact = new OrderContact();
      orderConstact.id = emptyUuid;
      orderConstact.firstName = customer.privateCustomer.firstName;
      orderConstact.lastName = customer.privateCustomer.lastName;
      orderConstact.email = customer.email;
      return orderConstact;
    }
  };
}

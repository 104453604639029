import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { invoiceApi } from "./InvoiceApi";
import type { Invoice } from "@/models/finance/Invoice";

export function useFinanceService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getInvoices = async (orderId: string): Promise<Invoice[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await invoiceApi.getInvoices(authHeaders, orderId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getInvoices };
}

import { CustomerSearchFilters } from "./CustomerSearchFilters";

export class CustomerSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new CustomerSearchFilters();

  constructor(query: string) {
    this.query = query;
  }

  public equals = (other: CustomerSearchRequest): boolean => {
    return (
      this.query === other.query &&
      this.page === other.page &&
      this.pageSize === other.pageSize &&
      this.filters.equals(other.filters)
    );
  };
}

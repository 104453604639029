import { creditNoteApi } from "../api/finance/CreditNoteApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import type { CreditNote } from "@/models/finance/CreditNote";
import { ref } from "vue";
import { defineStore } from "pinia";

export const useCreditStore = defineStore("credit-note", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const creditNotes = ref<CreditNote[]>([]);
  const loading = ref(false);

  const getCreditNotes = async (orderId: string): Promise<CreditNote[]> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      creditNotes.value = await creditNoteApi.list(authHeaders, orderId);
      return creditNotes.value;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    creditNotes,
    loading,
    getCreditNotes,
  };
});

import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "@/models/search/customer/CustomerSearchResponse";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useCustomerSearchService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const loading = ref(false);

  const customerRequest = ref<CustomerSearchRequest | null>(null);
  const customerResponse = ref<CustomerSearchResponse | null>(null);

  const customerSearch = async (
    request: CustomerSearchRequest,
    clearCache = false,
  ): Promise<CustomerSearchResponse> => {
    try {
      loading.value = true;

      if (clearCache) {
        customerRequest.value = null;
        customerResponse.value = null;
      }

      const authHeaders = await getAuthHeaders();

      if (customerRequest.value && customerRequest.value?.equals(request) && customerResponse.value !== null) {
        return customerResponse.value;
      }

      customerResponse.value = await searchApi.customerSearch(authHeaders, request);
      customerRequest.value = request;
      return customerResponse.value;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return new CustomerSearchResponse();
  };

  return { loading, customerSearch };
};

import { DefaultPrinterInfo } from "@/models/print/DefaultPrinterInfo";
import { PrintDocumentRequest } from "@/models/print/PrintDocumentRequest";
import { Printer } from "@/models/print/Printer";
import { httpClient, AuthHeaders } from "@cumulus/http";
import { DocumentType } from "@/models/print/DocumentType";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_DOCUMENT != "undefined" && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT + ""
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getOrderConfirmationUrl(authHeaders: AuthHeaders, orderId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", {
        params: { templateType: "OrderConfirmation", id: orderId },
      })
      .then(({ data }) => data);
  }

  public async getPackingNoteUrl(authHeaders: AuthHeaders, packingNoteId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", {
        params: { templateType: "PackingNote", id: packingNoteId },
      })
      .then(({ data }) => data);
  }

  public async getInvoiceUrl(authHeaders: AuthHeaders, invoiceId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", {
        params: { templateType: "Invoice", id: invoiceId },
      })
      .then(({ data }) => data);
  }

  public async getCreditNoteUrl(authHeaders: AuthHeaders, creditNoteId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get("/documents", {
        params: { templateType: "CreditNote", id: creditNoteId },
      })
      .then(({ data }) => data);
  }

  public async getPrinters(authHeaders: AuthHeaders): Promise<Printer[]> {
    return httpClient(this.uri, authHeaders)
      .get("/printing/printers")
      .then(({ data }) => data);
  }

  public async getDefaultPrinterByDocumentType(
    authHeaders: AuthHeaders,
    documentType: DocumentType,
  ): Promise<DefaultPrinterInfo> {
    return httpClient(this.uri, authHeaders)
      .get(`/printing/default-printers/${documentType}`)
      .then(({ data }) => data);
  }

  public async printDocument(authHeaders: AuthHeaders, request: PrintDocumentRequest): Promise<void> {
    return httpClient(this.uri, authHeaders)
      .put("/printing/print-document", request)
      .then(({ data }) => data);
  }
}

export const documentApi = new DocumentApi();

import { AuthHeaders, httpClient } from "@cumulus/http";
import { ProductAvailability } from "@/models/warehouse/ProductAvailability";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { PickingList } from "@/models/warehouse/PickingList";
import { PackingNote } from "@/models/warehouse/PackingNote";

class WarehouseApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}`
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Warehouse> {
    return await httpClient(this.uri, authHeaders)
      .get(`/warehouses/${id}`)
      .then(({ data }) => data);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Warehouse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/warehouses")
      .then(({ data }) => data);
  }

  public async getPickingList(authHeaders: AuthHeaders, orderId: string): Promise<PickingList[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/picking-lists?orderId=${orderId}`)
      .then(({ data }) => data);
  }

  public async getPackingNote(authHeaders: AuthHeaders, orderId: string): Promise<PackingNote[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/packing-notes?orderId=${orderId}`)
      .then(({ data }) => data);
  }

  public async getProductAvailability(authHeaders: AuthHeaders, productId: string): Promise<ProductAvailability[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/warehouses/products/${productId}/availability`)
      .then(({ data }) => data);
  }
}

export const warehouseApi = new WarehouseApi();

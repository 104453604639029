<template>
  <OrderSearchToolbar
    @orderRefresh="onRefreshList"
    @onToggleFilterSidebar="onToggleFilterSidebar"
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
  />
  <div class="c-order-search-result">
    <div class="flex">
      <div class="flex-auto c-transition">
        <Suspense>
          <OrderList
            :orders="orders"
            :loading="loading"
            :totalHits="totalHits"
            :pageSize="pageSize"
            :page="page"
            :sortField="sortField"
            :sortOrder="sortOrder"
            @downloadPdf="downloadPdf"
            @update:sortOrder="onUpdateSortOrder"
            @update:sortField="onUpdateSortField"
            @update:page="onUpdatePage"
            @update:pageSize="onUpdatePageSize"
            @orderRefresh="onRefreshList"
          />
        </Suspense>
      </div>

      <div
        class="c-transition pb-4 lg:pl-8"
        :class="{
          'show-filterbar': showSidebar,
        }"
      >
        <OrderFilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed, onDeactivated } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

import OrderSearchToolbar from "../components/search/OrderSearchToolbar.vue";
import { useOrderSearchStore } from "@/stores/OrderSearchStore";
import { SearchOrder } from "@/models/search/order/SearchOrder";

import { OrderSearchRequest } from "@/models/search/order/OrderSearchRequest";

import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import OrderFilterSidebar from "../components/search/OrderFilterSidebar.vue";
import OrderList from "../components/OrderList.vue";

import { useDocument } from "@/api/document/DocumentService";

const { t } = useI18n();
const route = useRoute();
const toast = useCumulusToast(useToast());
const { orderSearch } = useOrderSearchStore();

const pageSize = ref<number>(50);
const loading = ref(false);
const showSidebar = ref(false);
const query = ref("");
const orders = ref<SearchOrder[]>([]);
const totalHits = ref(0);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const filters = ref<OrderSearchFilters>(new OrderSearchFilters());

const { getOrderConfirmationUrl } = useDocument();

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  },
);

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

onMounted(async () => {
  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});

onDeactivated(() => {
  showSidebar.value = false;
});

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const filtersComputed = computed<OrderSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: OrderSearchFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await search();
};

const downloadPdf = async (id: string) => {
  const url = await getOrderConfirmationUrl(id);

  if (url && url.length > 0) {
    window.open(url, "_blank");
  } else {
    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: t("order.download-link-missing"),
    });
  }
};

watch(
  () => page.value,
  () => {
    search();
  },
);

const search = async () => {
  try {
    const request = new OrderSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;

    const response = await orderSearch(request);
    orders.value = response.orders;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped lang="scss">
.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  max-height: 90vh;
}
.c-order-search-result {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
</style>

import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { useAuth } from "@cumulus/event-bus";
import { Order } from "@/models/order/Order";
import { orderApi } from "@/api/order/OrderApi";
import { customerApi } from "@/api/customer/CustomerApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { Customer } from "@/models/customer/Customer";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { Address } from "@/models/order/Address";
import { Delivery } from "@/models/order/Delivery";
import { CustomerType } from "@/models/customer/CustomerType";
import { CalculatedOrderTotal } from "@/models/order/CalculatedOrderTotal";
import { useOrderCalculation } from "@/utils/calculation/OrderCalculation";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderLine } from "@/models/order/OrderLine";
import { useEmployee } from "@/api/employee/EmployeeService";
import { useCustomer } from "@/api/customer/CustomerService";
import isEqual from "lodash.isequal";

export const useEditOrderStore = defineStore("editOrderStore", () => {
  const { handleError, SeverityType, addToast } = useErrorHandler();
  const { getAuthHeaders, getUser } = useAuth();
  const { getEmployee } = useEmployee();
  const { getCustomer } = useCustomer();
  const { calculateOrderTotalPrice } = useOrderCalculation();
  const { t } = useI18n();

  const initialOrder = ref<Order>(new Order());
  const currentOrder = ref<Order>(new Order());
  const activeCustomer = ref<Customer | null>(null);
  const customerContacts = ref<CustomerContact[]>([]);
  const calculatedOrderTotal = ref<CalculatedOrderTotal>(new CalculatedOrderTotal());
  const selectedProductId = ref<string | null>(null);
  const focusFirstOrderLine = ref(false);

  const loading = ref(false);
  const isSaving = ref(false);

  const clearStore = async () => {
    initialOrder.value = new Order();
    currentOrder.value = new Order();
    await setSeller();
    activeCustomer.value = null;
    customerContacts.value = [];
    calculatedOrderTotal.value = new CalculatedOrderTotal();
    selectedProductId.value = null;
  };

  const getOrder = async (id: string): Promise<Order | null> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();

      if (currentOrder.value?.id === id) {
        return currentOrder.value;
      }

      await clearStore();

      const orderResult = await orderApi.get(authHeaders, id);

      if (!orderResult) {
        return null;
      }

      const customerResult = await customerApi.get(authHeaders, orderResult.customer.id);

      if (!customerResult) {
        return null;
      }

      currentOrder.value = orderResult;
      initialOrder.value = cloneDeep(orderResult);

      reCalculateOrderTotalPrice();

      focusFirstOrderLine.value = true;

      return currentOrder.value;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return null;
  };

  const updateOrder = async (): Promise<boolean> => {
    let success = true;
    try {
      isSaving.value = true;
      const authHeaders = await getAuthHeaders();

      await setSeller();
      await orderApi.update(authHeaders, currentOrder.value);

      initialOrder.value = cloneDeep(currentOrder.value);

      addToast(SeverityType.Success, t("order.edit.toast.success.summary"), t("order.edit.toast.success.detail"));
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      isSaving.value = false;
    }

    return success;
  };

  const deleteOrder = async (id: string): Promise<boolean> => {
    let success = true;
    try {
      const authHeaders = await getAuthHeaders();
      await orderApi.delete(authHeaders, id);
      await clearStore();
    } catch (error) {
      await handleError(error);
      success = false;
    }

    return success;
  };

  const setActiveCustomerById = async (id: string) => {
    const customer = await getCustomer(id);
    setActiveCustomer(customer);
  };

  const setActiveCustomer = (customer: Customer): void => {
    activeCustomer.value = customer;

    customerContacts.value = CustomerContact.createContactsFromCustomer(customer);
    currentOrder.value.customer = OrderCustomer.createFromCustomer(customer);

    currentOrder.value.invoiceAddress = Address.createFromCustomer(customer.address);
    currentOrder.value.delivery = Delivery.createFromCustomer(customer);

    calculatedOrderTotal.value = CalculatedOrderTotal.createFromOrder(currentOrder.value);
  };

  const isBusinessCustomer = computed<boolean>(() => {
    return currentOrder.value.customer?.customerType == CustomerType.Business && customerContacts.value.length > 1;
  });

  const reCalculateOrderTotalPrice = () => {
    calculatedOrderTotal.value = calculateOrderTotalPrice(
      currentOrder.value.orderLines,
      currentOrder.value.shippingPrice,
      currentOrder.value.freightMethod?.taxRate ?? 0,
    );
  };

  const allowEditOrder = computed<boolean>(() => {
    return !currentOrder.value.orderStatus || currentOrder.value.orderStatus === OrderStatus.Open;
  });

  const allowAddProducts = computed<boolean>(() => {
    return !currentOrder.value.orderStatus || currentOrder.value.orderStatus !== OrderStatus.Delivered;
  });

  const addOrderLine = (orderLine: OrderLine, warehouseId: string) => {
    orderLine.warehouseId = warehouseId;
    currentOrder.value.orderLines.push(orderLine);
    orderLine.positionNumber = currentOrder.value.orderLines.length;
    reCalculateOrderTotalPrice();
  };

  const getDefaultWarehouseId = async () => {
    const user = await getUser();
    if (user === null) {
      return "";
    }
    const employee = await getEmployee(user.getEmployee().id);
    return employee.warehouseId;
  };

  const isOrderChanged = computed(() => {
    if (initialOrder.value && initialOrder.value.orderStatus === "Picking") {
      return false;
    }

    return !isEqual(currentOrder.value, initialOrder.value);
  });

  const setSeller = async () => {
    currentOrder.value.seller = (await getUser()).getFullname();
    initialOrder.value.seller = currentOrder.value.seller;
  };

  return {
    isSaving,
    loading,
    currentOrder,
    activeCustomer,
    isBusinessCustomer,
    customerContacts,
    calculatedOrderTotal,
    selectedProductId,
    allowEditOrder,
    allowAddProducts,
    isOrderChanged,
    clearStore,
    getOrder,
    updateOrder,
    deleteOrder,
    setActiveCustomer,
    setActiveCustomerById,
    reCalculateOrderTotalPrice,
    addOrderLine,
    getDefaultWarehouseId,
    setSeller,
    focusFirstOrderLine,
  };
});

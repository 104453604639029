import { useAuth } from "@cumulus/event-bus";
import type { Employee } from "@/models/employee/Employee";
import { employeeApi } from "./EmployeeApi";
import { useErrorHandler } from "@/api/ErrorHandler";

export function useEmployee() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getEmployee = async (id: string): Promise<Employee> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await employeeApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getEmployee };
}

import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import type { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { paymentTermApi } from "./PaymentTermApi";

export function usePaymentTerms() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getPaymentTerms = async (): Promise<PaymentTerm[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await paymentTermApi.getforCustomers(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getPaymentTerms };
}

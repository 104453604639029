<template>
  <div class="mt-4 mx-6">
    <FloatLabel variant="on">
      <InputText
        id="contact-email"
        v-model="orderReferenceComputed"
        :label="t('order.contact.email-label')"
        :selectAllOnFocus="true"
        :fluid="true"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('order.contact.email-label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :dataTestId="'contact-email'"
        :class="{ 'p-disabled': !allowEdit }"
        :disabled="!allowEdit"
      />
      <label for="contact-email">{{ t("order.contact.email-label") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";

const { t } = useI18n();

const props = defineProps<{
  email: string;
  orderStatus?: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const orderReferenceComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});
const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>

export class ProductSearchByIdsRequest {
  includeAvailability = true;
  ids: string[] = [];

  constructor(ids: string[]) {
    this.ids = ids;
  }

  public static equals = (obj1: ProductSearchByIdsRequest | null, obj2: ProductSearchByIdsRequest | null): boolean => {
    if (obj1 === null || obj2 === null) {
      return false;
    }

    return obj1.includeAvailability === obj2.includeAvailability && obj1.ids === obj2.ids;
  };

  public static clone = (obj: ProductSearchByIdsRequest | null): ProductSearchByIdsRequest | null => {
    if (obj === null) {
      return null;
    }
    const clone = new ProductSearchByIdsRequest(obj.ids);
    clone.includeAvailability = obj.includeAvailability;
    return clone;
  };
}

import { Manufacturer } from "@/models/manufacturer/Manufacturer";
import { type AuthHeaders, httpClient } from "@cumulus/http";

class ManufacturerApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT as string}/manufacturers`
        : `${import.meta.env.VITE_APP_API_URL as string}/manufacturers`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Manufacturer> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }
}

const manufacturerApi = new ManufacturerApi();
export { manufacturerApi };

<template>
  <div class="mx-8">
    <FloatLabel variant="on">
      <InputText
        id="order-reference"
        v-model="orderReference"
        :label="t('order.order-reference.label')"
        :selectAllOnFocus="true"
        :dataTestId="'order-reference'"
        :disabled="!props.allowEditOrder"
        autocomplete="off"
        :fluid="true"
        v-tooltip.focus.bottom="{
          value: t('placeholder.type', { property: t('order.order-reference.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      /><label for="order-reference">{{ t("order.order-reference.label") }}</label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{ allowEditOrder: boolean }>();
const orderReference = defineModel<string>("orderReference");
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>

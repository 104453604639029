import { v4 as uuidv4 } from "uuid";
import { CustomerType } from "./CustomerType";
import { Customer } from "@/models/customer/Customer";

export class CustomerContact {
  id = uuidv4();
  firstName = "";
  lastName = "";
  email = "";

  public static createContactsFromCustomer(customer: Customer): CustomerContact[] {
    if (customer.customerType == CustomerType.Business) {
      return customer.businessCustomer.contacts;
    }

    const customerContact = new CustomerContact();
    customerContact.id = customer.id;
    customerContact.firstName = customer.privateCustomer.firstName;
    customerContact.lastName = customer.privateCustomer.lastName;
    customerContact.email = customer.email;
    return [customerContact];
  }
}

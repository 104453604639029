<template>
  <div class="p-float-label">
    <label class="ml-12" for="total-delivery">{{ t("order.delivery-option.total") }}</label>
    <ToggleSwitch
      inputId="total-delivery"
      v-model="totalDeliveryComputed"
      :disabled="!props.allowEditOrder"
      data-testid="delivery-option"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DeliveryOption } from "@/models/order/DeliveryOption";

const props = defineProps<{ allowEditOrder: boolean }>();
const deliveryOption = defineModel<DeliveryOption>("deliveryOption", { required: true });

const { t } = useI18n();

const totalDeliveryComputed = computed<boolean>({
  get: () => deliveryOption.value === DeliveryOption.Total,
  set: (value) => (deliveryOption.value = value ? DeliveryOption.Total : DeliveryOption.Partial),
});
</script>

import { OrderStatus } from "@/models/order/OrderStatus";
import { SearchOrderCustomer } from "./SearchOrderCustomer";

export class SearchOrder {
  id = "";
  orderNumber = 0;
  customer = new SearchOrderCustomer();
  orderReference = "";
  seller = "";
  comment = "";
  registered = "";
  clientId = "";
  companyId = "";
  orderStatus: OrderStatus = OrderStatus.Open;
}

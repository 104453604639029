<template>
  <div class="mt-4">
    <FloatLabelInputNumber
      id="shipping-price"
      v-model:value="shippingPriceComputed"
      :label="t('order.shipping-price')"
      :placeholder="t('placeholder.type', { property: t('order.shipping-price').toLowerCase() })"
      dataTestId="shipping-price"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      :isFloatingLabel="true"
      autofocus
      @keydown.enter.prevent="emit('closeShippingPricePanel')"
    />
  </div>

  <div class="flex justify-end mt-6">
    <Button
      :label="t('common.calculate-freight')"
      class="c-freight-button"
      :disabled="props.freightMethod.bring == null"
      data-testid="calculate-freight-button"
      @click="emit('shippingCalculationDialogVisible', true)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderFreightMethod } from "@/models/order/OrderFreightMethod";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  orderStatus?: OrderStatus;
  freightMethod: OrderFreightMethod;
}>();

const emit = defineEmits<{
  (e: "shippingCalculationDialogVisible", value: boolean): void;
  (e: "closeShippingPricePanel"): void;
}>();

const shippingPrice = defineModel<number>("shippingPrice", { required: true });
const agreedFreight = defineModel<boolean>("agreedFreight", { required: true });

const shippingPriceComputed = computed<number>({
  get: () => shippingPrice.value,
  set: (value: number | string) => {
    const price = value == null || value == "" || (value as number) < 0 ? 0 : (value as number);
    shippingPrice.value = price;
    agreedFreight.value = price > 0;
  },
});

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>

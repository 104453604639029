import { CustomerContact } from "@/models/customer/CustomerContact";

export class OrderContact {
  id: string = "";
  firstName = "";
  lastName = "";
  email = "";

  public static createFromCustomerContact = (customerContact: CustomerContact): OrderContact => {
    const contact = new OrderContact();
    contact.id = customerContact.id;
    contact.firstName = customerContact.firstName;
    contact.lastName = customerContact.lastName;
    contact.email = customerContact.email;
    return contact;
  };
}

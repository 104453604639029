export default {
  css: () => `
      .c-input-minimal .p-floatlabel-on:has(input:focus) label, 
      .c-input-minimal .p-floatlabel-on:has(input.p-filled) label, 
      .c-input-minimal .p-floatlabel-on:has(input:-webkit-autofill) label, 
      .c-input-minimal .p-floatlabel-on:has(textarea:focus) label, 
      .c-input-minimal .p-floatlabel-on:has(textarea.p-filled) label, 
      .c-input-minimal .p-floatlabel-on:has(.p-inputwrapper-focus) label, 
      .c-input-minimal .p-floatlabel-on:has(.p-inputwrapper-filled) label {
        margin-left: -0.65rem;
      }
      `,
};

export class ProductSearchFilters {
  activeOnWeb: boolean | null = null;
  manufacturers: string[] = [];

  public static equals = (obj1: ProductSearchFilters, obj2: ProductSearchFilters): boolean => {
    if (obj1 === null || obj2 === null) {
      return false;
    }

    return obj1.activeOnWeb === obj2.activeOnWeb && obj1.manufacturers.toString() === obj2.manufacturers.toString();
  };

  public static clone = (obj: ProductSearchFilters): ProductSearchFilters => {
    const clone = new ProductSearchFilters();
    clone.activeOnWeb = obj.activeOnWeb;
    clone.manufacturers = obj.manufacturers.map((manufacturer) => manufacturer);
    return clone;
  };
}

import { OrderLine } from "@/models/order/OrderLine";
import { CalculationBase } from "@/models/order/CalculationBase";
import { CalculatedOrderLinePrice } from "@/models/order/CalculatedOrderLinePrice";
import { CalculatedOrderTotal } from "@/models/order/CalculatedOrderTotal";
import { PriceCalculation } from "./PriceCalculation";

export function useOrderCalculation() {
  const decimalPlaces = 2;

  const calculateOrderLinePrice = (
    orderLine: OrderLine,
    calculationBase: CalculationBase,
  ): CalculatedOrderLinePrice => {
    const calculatedOrderLinePrice = new CalculatedOrderLinePrice();

    switch (calculationBase) {
      case CalculationBase.Price:
        calculatedOrderLinePrice.price = orderLine.price;
        break;
      case CalculationBase.PriceIncVat:
        calculatedOrderLinePrice.price = PriceCalculation.removeVat(
          orderLine.priceIncVat,
          orderLine.vatPercentage,
          decimalPlaces,
        );
        break;
      case CalculationBase.ContributionMargin:
        calculatedOrderLinePrice.price = PriceCalculation.priceFromContributionMargin(
          orderLine.costPrice,
          orderLine.contributionMargin,
          decimalPlaces,
        );
        break;
      case CalculationBase.DiscountPercentage:
        calculatedOrderLinePrice.price = PriceCalculation.applyDiscount(
          orderLine.listPrice,
          orderLine.discountPercentage,
          decimalPlaces,
        );
        break;
      default:
        throw "Unknown calculation base";
    }

    calculatedOrderLinePrice.priceIncVat = PriceCalculation.addVat(
      calculatedOrderLinePrice.price,
      orderLine.vatPercentage,
      decimalPlaces,
    );
    calculatedOrderLinePrice.contributionMargin = PriceCalculation.calculateContributionMargin(
      orderLine.costPrice,
      calculatedOrderLinePrice.price,
      decimalPlaces,
    );
    calculatedOrderLinePrice.discountPercentage = PriceCalculation.calculateDiscount(
      orderLine.listPrice,
      calculatedOrderLinePrice.price,
      decimalPlaces,
    );
    calculatedOrderLinePrice.sumLine = orderLine.quantity * calculatedOrderLinePrice.price;

    return calculatedOrderLinePrice;
  };

  const calculateOrderTotalPrice = (
    orderLines: OrderLine[],
    shippingPrice: number,
    shippingVatPercentage: number,
  ): CalculatedOrderTotal => {
    const calculatedOrderTotal = new CalculatedOrderTotal();

    calculatedOrderTotal.shippingPriceIncVat = PriceCalculation.calculateShippingPriceIncVat(
      shippingPrice,
      shippingVatPercentage,
      decimalPlaces,
    );

    if (orderLines.length == 0) {
      return calculatedOrderTotal;
    }

    calculatedOrderTotal.sumTotalLines = PriceCalculation.calculateSumTotalLines(orderLines, decimalPlaces);
    calculatedOrderTotal.sumTotalLinesIncVat = PriceCalculation.calculateSumTotalLinesIncVat(orderLines, decimalPlaces);
    calculatedOrderTotal.sumTotalLinesVatAmount = PriceCalculation.calculateSumTotalLinesVatAmount(
      calculatedOrderTotal.sumTotalLines,
      calculatedOrderTotal.sumTotalLinesIncVat,
      decimalPlaces,
    );
    calculatedOrderTotal.totalSumIncVat = PriceCalculation.calculateTotalSumIncVat(
      orderLines,
      calculatedOrderTotal.shippingPriceIncVat,
      decimalPlaces,
    );
    calculatedOrderTotal.totalVatAmount = PriceCalculation.calculateTotalVatAmount(
      calculatedOrderTotal.sumTotalLinesVatAmount,
      calculatedOrderTotal.shippingPriceIncVat - shippingPrice,
      decimalPlaces,
    );
    calculatedOrderTotal.totalContributionMarginPercentage =
      PriceCalculation.calculateTotalContributionMarginPercentage(orderLines, decimalPlaces);
    calculatedOrderTotal.totalContributionMargin = PriceCalculation.calculateTotalContributionMargin(
      orderLines,
      decimalPlaces,
    );
    calculatedOrderTotal.totalDiscountAmount = PriceCalculation.calculateTotalDiscountAmount(orderLines, decimalPlaces);
    calculatedOrderTotal.totalDiscountPercentage = PriceCalculation.calculateTotalDiscountPercentage(
      orderLines,
      calculatedOrderTotal.totalDiscountAmount,
      decimalPlaces,
    );
    return calculatedOrderTotal;
  };

  const calculatePriceForOrderLine = (orderLine: OrderLine, calculationBase: CalculationBase) => {
    const calculatedOrderLinePrice = calculateOrderLinePrice(orderLine, calculationBase);
    orderLine.price = calculatedOrderLinePrice.price;
    orderLine.priceIncVat = calculatedOrderLinePrice.priceIncVat;
    orderLine.contributionMargin = calculatedOrderLinePrice.contributionMargin;
    orderLine.discountPercentage = calculatedOrderLinePrice.discountPercentage;
    orderLine.sumLine = calculatedOrderLinePrice.sumLine;
  };

  const updateQuantity = (value: number, orderLine: OrderLine) => {
    orderLine.quantity = value;
    calculatePriceForOrderLine(orderLine, CalculationBase.Price);
  };

  const updatePriceExVat = (value: number, orderLine: OrderLine) => {
    orderLine.price = value;
    calculatePriceForOrderLine(orderLine, CalculationBase.Price);
  };

  const updatePriceIncVat = (value: number, orderLine: OrderLine) => {
    orderLine.priceIncVat = value;
    calculatePriceForOrderLine(orderLine, CalculationBase.PriceIncVat);
  };

  const updateDiscount = (value: number, orderLine: OrderLine) => {
    orderLine.discountPercentage = value;
    calculatePriceForOrderLine(orderLine, CalculationBase.DiscountPercentage);
  };

  const updateContributionMargin = (value: number, orderLine: OrderLine) => {
    orderLine.contributionMargin = value;
    calculatePriceForOrderLine(orderLine, CalculationBase.ContributionMargin);
  };

  return {
    updateQuantity,
    updatePriceExVat,
    updatePriceIncVat,
    updateDiscount,
    updateContributionMargin,
    calculateOrderLinePrice,
    calculateOrderTotalPrice,
  };
}

import { httpClient, AuthHeaders } from "@cumulus/http";
import { Invoice } from "@/models/finance/Invoice";

class InvoiceApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/invoices"
        : `${import.meta.env.VITE_APP_API_URL as string}/invoices`;
  }

  public async getInvoices(authHeaders: AuthHeaders, orderId: string): Promise<Invoice[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`?orderId=${orderId}`)
      .then(({ data }) => data);
  }
}

export const invoiceApi = new InvoiceApi();

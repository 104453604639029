import { OrderCustomer } from "./OrderCustomer";
import { OrderLine } from "./OrderLine";
import { Address } from "./Address";
import { Delivery } from "./Delivery";
import { DeliveryOption } from "./DeliveryOption";
import { NIL as emptyUuid } from "uuid";
import { OrderFreightMethod } from "./OrderFreightMethod";
import { OrderPaymentTerm } from "./OrderPaymentTerm";
import { OrderStatus } from "./OrderStatus";

export class Order {
  id: string = emptyUuid;
  orderNumber = 0;
  customer = new OrderCustomer();
  delivery = new Delivery();
  invoiceAddress = new Address();
  orderLines: OrderLine[] = [];
  orderReference = "";
  seller = "";
  deliveryOption = DeliveryOption.Total;
  comment = "";
  paymentTerm = new OrderPaymentTerm(emptyUuid, "", 0);
  freightMethod = new OrderFreightMethod();
  warehouseId = "";
  orderStatus = OrderStatus.Open;
  registered = "";
  shippingPrice = 0;
  shippingPriceIncVat = 0;
  sumTotalLines = 0;
  sumTotalLinesIncVat = 0;
  sumTotalLinesVatAmount = 0;
  totalSumIncVat = 0;
  totalContributionMargin = 0;
  totalContributionMarginPercentage = 0;
  totalDiscountAmount = 0;
  totalDiscountPercentage = 0;
  totalVatAmount = 0;
  agreedFreight: boolean = false;
}

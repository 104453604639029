import { setupI18n } from "@cumulus/locale";
import type { LocaleMessageDictionary, VueMessageType } from "vue-i18n";
import en from "./en.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const messages = await import(`../locales/${locale}.json`);
  return messages.default;
}

export const i18n = setupI18n(en as unknown as string, loadMessagesAsync);


<template>
  <div class="field my-6">
    <DataTable
      dataKey="id"
      :value="productAvailabilityList"
      responsiveLayout="scroll"
      selectionMode="single"
      data-testid="product-warehouse-availability-table"
      class="c-availability-table c-datatable"
      striped-rows
      scrollable
      scrollHeight="12rem"
      :pt="{
        bodyrow: 'bg-slate-50 dark:bg-primary-400/10 text-base',
        bodyCell: 'p-0 m-0',
      }"
    >
      <Column field="warehouseName" :header="t('product-warehouse-availability.warehouse-name')" class="max-w-32">
        <template #body="{ data, field, index }">
          <div class="text-ellipsis whitespace-nowrap overflow-hidden" :data-testid="`warehouse-name-${index}`">
            {{ data[field as keyof typeof data] }}
          </div>
        </template>
      </Column>
      <Column field="quantityInStock" :header="t('product-warehouse-availability.quantity-in-stock')">
        <template #body="{ data, field, index }">
          <div class="flex justify-content-end" :data-testid="`quantity-in-stock-${index}`">
            {{ n(data[field as keyof typeof data], "decimal") }}
          </div>
        </template>
      </Column>

      <Column field="quantityInOrders" :header="t('product-warehouse-availability.quantity-in-orders')">
        <template #body="{ data, field, index }">
          <div class="flex justify-content-end" :data-testid="`quantity-in-orders-${index}`">
            {{ n(data[field as keyof typeof data], "decimal") }}
          </div>
        </template>
      </Column>

      <template #empty>
        <div class="flex justify-content-start">
          {{
            t(
              productId == null
                ? "product-warehouse-availability.select-product"
                : "product-warehouse-availability.not-found",
            )
          }}
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ProductAvailability } from "@/models/warehouse/ProductAvailability";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

const props = defineProps<{
  productId: string | null;
}>();

const { getProductAvailability } = useWarehouse();

const productAvailabilityList = ref<ProductAvailability[]>();

watch(
  () => props.productId,
  async (newValue) => await fetchAvailability(newValue),
);

onMounted(async () => await fetchAvailability(props.productId));

const fetchAvailability = async (productId: string | null) => {
  if (productId == null) {
    productAvailabilityList.value = [];
    return;
  }
  const result = await getProductAvailability(productId);
  productAvailabilityList.value = result;
};
</script>

import { httpClient, type AuthHeaders } from "@cumulus/http";
import { BringShippingGuideRequest } from "./BringShippingGuideRequest";

class BringApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/bring-integration`
        : `${import.meta.env.VITE_APP_API_URL as string}/bring-integration`;
  }

  public async getBringShippingGuide(authHeaders: AuthHeaders, data: BringShippingGuideRequest) {
    return await httpClient(this.uri, authHeaders)
      .post("/shipping-guides", data)
      .then(({ data }) => data);
  }
}

const bringApi = new BringApi();
export { bringApi };

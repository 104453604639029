import { ProductHierarchy } from "@/models/product/ProductHierarchy";
import { AuthHeaders, httpClient } from "@cumulus/http";

class ProductHierarchyApi {
  private uri = "";

  constructor() {
    this.uri = import.meta.env.VITE_APP_API_PRODUCT
      ? import.meta.env.VITE_APP_API_PRODUCT
      : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductHierarchies(authHeaders: AuthHeaders): Promise<ProductHierarchy[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/product-hierarchies")
      .then(({ data }) => data);
  }
}

const productHierarchyApi = new ProductHierarchyApi();
export { productHierarchyApi };

<template>
  <div class="flex items-center gap-2 mb-4 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <Button
        id="btn-commit"
        class="c-circular-button mr-4"
        data-testid="btn-commit"
        @click="router.push({ name: 'order-add' })"
      >
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-2 font-normal">{{ t("common.add") }}</span>
      </Button>
    </div>
    <div>
      <Button
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('order.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('order.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('order.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        data-testid="filter-button"
        severity="secondary"
        variant="text"
        size="small"
        v-tooltip.bottom="{
          value: t('order.toolbar.filter'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        @click="emit('onToggleFilterSidebar')"
      >
        <i class="pi pi-filter"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "onToggleFilterSidebar"): void;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });

const router = useRouter();
const { t } = useI18n();
</script>

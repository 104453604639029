import { useErrorHandler } from "@/api/ErrorHandler";
import { Manufacturer } from "@/models/manufacturer/Manufacturer";
import { useAuth } from "@cumulus/event-bus";
import { manufacturerApi } from "./ManufacturerApi";

export function useManufacturer() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getManufacturerById = async (id: string): Promise<Manufacturer> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await manufacturerApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getManufacturerById };
}

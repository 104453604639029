<template>
  <div class="mt-1">
    <div class="field my-6">
      <label for="order-comment" class="c-order-history-title text-base font-bold"
        >{{ t(`order.order-history.title`) }}
      </label>

      <DataTable
        ref="tableOrderLines"
        dataKey="timestamp"
        :value="historyData"
        responsiveLayout="scroll"
        selectionMode="single"
        data-testid="order-history-table"
        class="c-datatable"
        striped-rows
        scrollable
        scrollHeight="12rem"
        :size="'small'"
        :pt="{
          bodyrow: 'bg-slate-50 dark:bg-primary-400/10',
        }"
      >
        <Column field="timestamp" class="w-4/12" />
        <Column field="name" class="w-4/12" />
        <Column field="reference" class="w-4/12">
          <template #body="{ data, field }">
            <div
              :class="{ underline: data[field as keyof typeof data].download }"
              @click="data[field as keyof typeof data].download"
            >
              {{ data[field as keyof typeof data].referenceNumber }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>

    <ProductAvailabilityList :productId="props.selectedProductId" />

    <ProductPurchaseQuantityList :productId="props.selectedProductId" />

    <ProductSupplierPriceList :productId="props.selectedProductId" />
  </div>
</template>

<script setup lang="ts">
//Components
import ProductAvailabilityList from "../warehouse/ProductAvailabilityList.vue";
import ProductPurchaseQuantityList from "../purchase/ProductPurchaseQuantityList.vue";
import ProductSupplierPriceList from "../supplier/ProductSupplierPriceList.vue";

import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useDocument } from "@/api/document/DocumentService";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { useFinanceService } from "@/api/finance/InvoiceService";
import { useCreditStore } from "@/stores/CreditNoteStore";
import { NIL as emptyUuid } from "uuid";
import { Order } from "@/models/order/Order";

const props = defineProps<{
  selectedProductId: string | null;
  allowEditOrder: boolean;
}>();

const currentOrder = defineModel<Order>("currentOrder", { required: true });

const { t, d } = useI18n();

const toast = useCumulusToast(useToast());
const { getOrderConfirmationUrl, getPackigNoteUrl, getInvoiceUrl, getCreditNoteUrl } = useDocument();

const downloadPdf = (asyncUrl: Promise<string> | string) => async () => {
  const url = await asyncUrl;

  if (url && url.length > 0) {
    window.open(url, "_blank");
  } else {
    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: t("order.download-link-missing"),
    });
  }
};

type OrderHistoryData = {
  timestamp: string;
  name: string;
  reference: {
    referenceNumber: string;
    download?: () => void;
  };
};

const historyData = ref<OrderHistoryData[]>([]);

const { getPickingLists, getPackingNotes } = useWarehouse();
const { getInvoices } = useFinanceService();
const { getCreditNotes } = useCreditStore();

const addCurrentOrderToHistory = () => {
  if (historyData.value.length > 0) {
    return;
  }

  if (currentOrder.value.id === emptyUuid) {
    return;
  }
  historyData.value = [
    {
      timestamp: currentOrder.value.registered && d(currentOrder.value.registered, "long"),
      name: t("order.order-history.confirmation-line"),
      reference: {
        referenceNumber: currentOrder.value.orderNumber.toString(),
        download: downloadPdf(getOrderConfirmationUrl(currentOrder.value.id)),
      },
    },
  ];
};

const fetchOrderHistory = async () => {
  historyData.value = [];

  if (!currentOrder.value || currentOrder.value.id === emptyUuid) {
    return;
  }

  addCurrentOrderToHistory();

  const [pickingLists, packingNotes, invoices, creditNotes] = await Promise.all([
    getPickingLists(currentOrder.value.id),
    getPackingNotes(currentOrder.value.id),
    getInvoices(currentOrder.value.id),
    getCreditNotes(currentOrder.value.id),
  ]);

  const newHistoryData: OrderHistoryData[][] = pickingLists.map((pl) => {
    const data: OrderHistoryData[] = [
      {
        timestamp: pl.pickingStarted !== null ? d(pl.pickingStarted, "long") : "",
        name: t("order.order-history.picking-list-line"),
        reference: {
          referenceNumber: pl.pickingListNumber.toString(),
        },
      },
    ];

    const packingNote = packingNotes.find((p) => p.pickingListId === pl.id);
    if (packingNote) {
      data.push({
        timestamp: "",
        name: t("order.order-history.packing-note-line"),
        reference: {
          referenceNumber: packingNote.packingNoteNumber.toString(),
          download: downloadPdf(getPackigNoteUrl(packingNote.id)),
        },
      });

      data.push({
        timestamp: "",
        name: t("order.order-history.shipment-number"),
        reference: {
          referenceNumber: packingNote.consignmentNumber,
          download: downloadPdf(packingNote.freightTrackingLink),
        },
      });

      const invoice = invoices.find((i) => i.packingNoteNumber === packingNote.packingNoteNumber);
      if (invoice) {
        data.push({
          timestamp: d(invoice.invoiceDate, "long"),
          name: t("order.order-history.invoice-line"),
          reference: {
            referenceNumber: invoice.invoiceNumber.toString(),
            download: downloadPdf(getInvoiceUrl(invoice.id)),
          },
        });

        const creditNotesForInvoice = creditNotes.filter((c) =>
          c.creditNoteLines.map((l) => l.invoiceId).includes(invoice.id),
        );
        for (const creditNoteForInvoice of creditNotesForInvoice) {
          data.push({
            timestamp: d(creditNoteForInvoice.creditNoteDate, "long"),
            name: t("order.order-history.credit-note-line"),
            reference: {
              referenceNumber: creditNoteForInvoice.creditNoteNumber.toString(),
              download: downloadPdf(getCreditNoteUrl(creditNoteForInvoice.id)),
            },
          });
        }
      }
    }

    return data;
  });

  newHistoryData.forEach((data) => {
    historyData.value = historyData.value.concat(data);
  });
};

watch(currentOrder, async () => {
  await fetchOrderHistory();
});

onMounted(async () => {
  await fetchOrderHistory();
});
</script>

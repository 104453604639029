<template>
  <div class="mt-5 ml-6 border-slate-300 mr-6">
    <FloatLabel variant="on">
      <InputText
        id="order-comment"
        ref="commentInputRef"
        v-model="comment"
        :class="{ 'p-disabled': !allowEdit }"
        :disabled="!allowEdit"
        maxlength="15000"
        data-testId="order-comment"
        :pt:overlay:class="'hidden'"
        :fluid="true"
        @keydown="handleKeydown"
        @click="showCommentPanel"
      />
      <label for="order-comment">{{ t("order.comment.label") }}</label>
    </FloatLabel>
    <CommentPanel ref="commentPanelRef" v-model:comment="comment" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  orderStatus?: OrderStatus;
}>();

const comment = defineModel<string>("comment");

const allowEdit = computed<boolean>(() => {
  return (
    !props.orderStatus || props.orderStatus === OrderStatus.Open || props.orderStatus === OrderStatus.PartiallyDelivered
  );
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }
  commentPanelRef.value.toggle(event);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter" || event.key === " ") {
    commentPanelRef.value.toggle(event);
  }
};
</script>

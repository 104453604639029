import { Order } from "@/models/order/Order";

export class CalculatedOrderTotal {
  sumTotalLines = 0;
  sumTotalLinesIncVat = 0;
  sumTotalLinesVatAmount = 0;
  totalSumIncVat = 0;
  totalVatAmount = 0;
  totalContributionMargin = 0;
  totalContributionMarginPercentage = 0;
  totalDiscountPercentage = 0;
  totalDiscountAmount = 0;
  shippingPriceIncVat = 0;

  public static createFromOrder(order: Order): CalculatedOrderTotal {
    const response = new CalculatedOrderTotal();
    response.totalContributionMarginPercentage = order.totalContributionMarginPercentage;
    response.totalContributionMargin = order.totalContributionMargin;
    response.totalDiscountPercentage = order.totalDiscountPercentage;
    response.totalDiscountAmount = order.totalDiscountAmount;
    response.sumTotalLines = order.sumTotalLines;
    response.sumTotalLinesIncVat = order.sumTotalLinesIncVat;
    response.sumTotalLinesVatAmount = order.sumTotalLinesVatAmount;
    response.totalSumIncVat = order.totalSumIncVat;
    response.totalVatAmount = order.totalVatAmount;
    response.shippingPriceIncVat = order.shippingPriceIncVat;

    return response;
  }
}

import type { SearchProduct } from "@/models/search/product/SearchProduct";
import { Weight } from "@/models/search/product/Weight";
import { NIL as emptyUuid } from "uuid";
export class OrderProduct {
  id: string = emptyUuid;
  name = "";
  productNumber = "";
  gtin = "";
  primaryImageUrl = "";
  description = "";
  manufacturerId: string = emptyUuid;
  productHierarchyId: string = emptyUuid;
  weight: Weight = new Weight();

  public static createFromProduct(product: SearchProduct): OrderProduct {
    const orderProduct = new OrderProduct();
    orderProduct.id = product.id;
    orderProduct.name = product.name;
    orderProduct.productNumber = product.productNumber;
    orderProduct.description = product.description;
    orderProduct.gtin = product.gtin;
    orderProduct.primaryImageUrl = product.productImages.length > 0 ? product.productImages[0].url : "";
    orderProduct.weight = product.weight;
    orderProduct.manufacturerId = product.manufacturerId;
    orderProduct.productHierarchyId = product.productHierarchyId;
    return orderProduct;
  }
}

import { type AuthHeaders, httpClient } from "@cumulus/http";
import { ProductPurchaseQuantity } from "@/models/purchase/ProductPurchaseQuantity";

class PurchaseApi {
  private uri = "";

  constructor() {
    this.uri =
      import.meta.env.VITE_APP_API_PRODUCT != undefined && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getProductPurchaseQuantity(
    authHeaders: AuthHeaders,
    productId: string,
  ): Promise<ProductPurchaseQuantity[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/purchase-orders/products/${productId}/purchase-quantity`)
      .then(({ data }) => data);
  }
}

export const purchaseApi = new PurchaseApi();

import { OrderLine } from "@/models/order/OrderLine";

export class PriceCalculation {
  public static bankersRounding(num: number, decimalPlaces: number): number {
    const d = decimalPlaces || 0;
    const m = Math.pow(10, d);
    const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    const i = Math.floor(n),
      f = n - i;
    const e = 1e-8; // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  }

  public static addVat(value: number, vatPercentage: number, decimalPlaces: number): number {
    const vat = value * (1 + vatPercentage / 100);
    return PriceCalculation.bankersRounding(vat, decimalPlaces);
  }

  public static removeVat(value: number, vatPercentage: number, decimalPlaces: number): number {
    const removedVat = value / (1 + vatPercentage / 100);
    return PriceCalculation.bankersRounding(removedVat, decimalPlaces);
  }

  public static calculateContributionMargin(costPrice: number, price: number, decimalPlaces: number): number {
    const cm = price > 0 ? ((price - costPrice) / price) * 100 : 0;
    return PriceCalculation.bankersRounding(cm, decimalPlaces);
  }

  public static priceFromContributionMargin(
    costPrice: number,
    contributionMargin: number,
    decimalPlaces: number,
  ): number {
    if (contributionMargin == 100) {
      return 0;
    } else if (contributionMargin == 0) {
      return PriceCalculation.bankersRounding(costPrice, decimalPlaces);
    } else {
      const cp = costPrice / (1 - contributionMargin / 100);
      return PriceCalculation.bankersRounding(cp, decimalPlaces);
    }
  }

  public static calculateDiscount(listPrice: number, price: number, decimalPlaces: number): number {
    const discount = listPrice > 0 ? (listPrice - price) / (listPrice / 100) : 0;
    return discount < 0 ? 0 : PriceCalculation.bankersRounding(discount, decimalPlaces);
  }

  public static applyDiscount(value: number, discountPercentage: number, decimalPlaces: number): number {
    const discount = value * (1 - discountPercentage / 100);
    return PriceCalculation.bankersRounding(discount, decimalPlaces);
  }

  public static calculateSumTotalLines(orderLines: OrderLine[], decimalPlaces: number): number {
    if (orderLines.length == 0) {
      return 0;
    }

    const sum = orderLines.reduce((accumulator, x) => {
      return accumulator + x.sumLine;
    }, 0);

    return PriceCalculation.bankersRounding(sum, decimalPlaces);
  }

  public static calculateTotalContributionMargin(orderLines: OrderLine[], decimalPlaces: number): number {
    if (orderLines.length == 0) {
      return 0;
    }
    const totalCostPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.costPrice * x.quantity;
    }, 0);

    const totalSalesPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.price * x.quantity;
    }, 0);

    const sum = totalSalesPrice - totalCostPrice;
    return PriceCalculation.bankersRounding(sum, decimalPlaces);
  }

  public static calculateTotalContributionMarginPercentage(orderLines: OrderLine[], decimalPlaces: number): number {
    if (orderLines.length == 0) {
      return 0;
    }

    const totalCostPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.costPrice * x.quantity;
    }, 0);

    const totalSalesPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.price * x.quantity;
    }, 0);

    let totalCmPercentage = totalSalesPrice > 0 ? ((totalSalesPrice - totalCostPrice) * 100) / totalSalesPrice : 0;
    if (totalCmPercentage > 100) {
      totalCmPercentage = 100;
    }
    if (totalCmPercentage < 0) {
      totalCmPercentage = 0;
    }
    return PriceCalculation.bankersRounding(totalCmPercentage, decimalPlaces);
  }

  public static calculateTotalDiscountAmount(orderLines: OrderLine[], decimalPlaces: number): number {
    if (orderLines.length == 0) {
      return 0;
    }

    const totalSalesPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.price * x.quantity;
    }, 0);

    const totalListPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.listPrice * x.quantity;
    }, 0);

    const discount = totalListPrice - totalSalesPrice;
    const totalDiscountAmount = discount > 0 ? discount : 0;
    return PriceCalculation.bankersRounding(totalDiscountAmount, decimalPlaces);
  }

  public static calculateTotalDiscountPercentage(
    orderLines: OrderLine[],
    totalDiscountAmount: number,
    decimalPlaces: number,
  ): number {
    if (orderLines.length == 0) {
      return 0;
    }

    const totalListPrice = orderLines.reduce((accumulator, x) => {
      return accumulator + x.listPrice * x.quantity;
    }, 0);

    const discountPercentage = totalListPrice > 0 ? (totalDiscountAmount * 100) / totalListPrice : 0;
    const totalDiscountPercentage = discountPercentage > 0 ? discountPercentage : 0;
    return PriceCalculation.bankersRounding(totalDiscountPercentage, decimalPlaces);
  }

  public static calculateSumTotalLinesIncVat(orderLines: OrderLine[], decimalPlaces: number): number {
    if (orderLines.length == 0) {
      return 0;
    }

    const totalIncVat = orderLines.reduce((accumulator, x) => {
      return accumulator + PriceCalculation.addVat(x.sumLine, x.vatPercentage, decimalPlaces);
    }, 0);

    return PriceCalculation.bankersRounding(totalIncVat, decimalPlaces);
  }

  public static calculateTotalSumIncVat(
    orderLines: OrderLine[],
    shippingPriceIncVat: number,
    decimalPlaces: number,
  ): number {
    if (orderLines.length == 0) {
      return 0;
    }
    const sumTotalLinesIncVat = PriceCalculation.calculateSumTotalLinesIncVat(orderLines, decimalPlaces);
    const totalSumIncVat = sumTotalLinesIncVat + shippingPriceIncVat;
    return PriceCalculation.bankersRounding(totalSumIncVat, decimalPlaces);
  }

  public static calculateSumTotalLinesVatAmount(
    sumTotalLines: number,
    sumTotalLinesIncVat: number,
    decimalPlaces: number,
  ): number {
    const vatAmount = sumTotalLinesIncVat - sumTotalLines;
    return PriceCalculation.bankersRounding(vatAmount, decimalPlaces);
  }

  public static calculateShippingPriceIncVat(
    shippingPrice: number,
    vatPercentage: number,
    decimalPlaces: number,
  ): number {
    return PriceCalculation.addVat(shippingPrice, vatPercentage, decimalPlaces);
  }

  public static calculateTotalVatAmount(
    sumTotalLinesVatAmount: number,
    shippingVatAmount: number,
    decimalPlaces: number,
  ): number {
    const vatAmount = sumTotalLinesVatAmount + shippingVatAmount;
    return PriceCalculation.bankersRounding(vatAmount, decimalPlaces);
  }
}

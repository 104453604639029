import { purchaseApi } from "./PurchaseApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { ProductPurchaseQuantity } from "@/models/purchase/ProductPurchaseQuantity";
import { useAuth } from "@cumulus/event-bus";

export function usePurchase() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductPurchaseQuantity = async (productId: string): Promise<ProductPurchaseQuantity[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await purchaseApi.getProductPurchaseQuantity(authHeaders, productId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };
  return { getProductPurchaseQuantity };
}

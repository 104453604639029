<template>
  <Teleport to="#c-mobile-toolbar" v-if="isMobile">
    <PrimeButton class="p-button-text" @click="onAddNewOrder" data-testid="btn-add-mobile">
      <i class="pi pi-plus"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.add") }}</span>
    </PrimeButton>
  </Teleport>
  <Teleport to="#c-more-options-menu" v-if="isMobile">
    <PrimeButton class="p-button-text" @click="emit('onToggleFilterSidebar')" data-testid="btn-filter-mobile">
      <i class="pi pi-filter"></i>
      <span class="c-mobile-toolbar-text">{{
        showSidebar ? t("order.search.hide-filter") : t("order.search.show-filter")
      }}</span>
    </PrimeButton>
  </Teleport>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button mr-4" @click="onAddNewOrder" data-testid="btn-add">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.add") }}</span>
        </PrimeButton>
      </div>

      <div v-if="!isMobile">
        <FilterOverview v-model:filters="filtersComputed" />
      </div>
      <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')" data-testid="filter-button">
        <i class="pi pi-filter c-default-button c-circular-icon"></i>
        <span class="px-4">{{ showSidebar ? t("order.search.hide-filter") : t("order.search.show-filter") }}</span>
      </PrimeButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FilterOverview from "./FilterOverview.vue";
import { useMobile } from "@/utils/MobileService";

const props = defineProps<{
  showSidebar: boolean;
  filters: OrderSearchFilters;
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "orderRefresh"): void;
  (e: "addNewOrder"): void;
  (e: "update:filters", value: OrderSearchFilters): void;
}>();
const router = useRouter();
const { t } = useI18n();

const { isMobile } = useMobile();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: OrderSearchFilters) {
    emit("update:filters", value);
  },
});

const onAddNewOrder = () => {
  emit("orderRefresh");
  router.push({ name: "order-add" });
};
</script>

import { ref } from "vue";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { ProductHierarchy } from "@/models/product/ProductHierarchy";
import { productHierarchyApi } from "./ProductHierarchyApi";

export function useProductHierarchy() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productHierarchies = ref<ProductHierarchy[]>([]);
  const loading = ref(false);

  const getProductHierarchy = async (productHierarchyId: string): Promise<ProductHierarchy | null> => {
    try {
      loading.value = true;
      if (productHierarchies.value.length === 0) {
        await getProductHierarchies();
      }

      return productHierarchies.value.find((x: ProductHierarchy) => x.id === productHierarchyId) ?? null;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
    return null;
  };

  const getProductHierarchies = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      productHierarchies.value = await productHierarchyApi.getProductHierarchies(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };
  return {
    productHierarchies,
    getProductHierarchy,
    getProductHierarchies,
  };
}

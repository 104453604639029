export class ProductSearchByIdsRequest {
  includeAvailability = true;
  ids: string[] = [];

  constructor(ids: string[]) {
    this.ids = ids;
  }

  public equals = (other: ProductSearchByIdsRequest): boolean => {
    return this.includeAvailability === other.includeAvailability && this.ids === other.ids;
  };
}

export default {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  css: ({ dt }) => `

      /* Default */
      .c-datatable-default .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
          background: transparent;
      }
      .c-datatable-default .c-datatable.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
        background: var(--p-datatable-row-hover-background);
      }

      .c-datatable-default .c-datatable .p-datatable-paginator-bottom {
        border-bottom: none;
      }

      /* Gridlines */
      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr > td,
      .c-datatable-gridlines .c-datatable-no-border.p-datatable .p-datatable-tbody > tr > td {
          border: none;
      }
        
      .c-datatable th.p-datatable-sortable-column,
      .c-datatable-sort th.p-datatable-sortable-column {

        svg.p-datatable-sort-icon {
          visibility: hidden;

          &:not([sortOrder="0"]) {
            visibility: visible;  
          }
        }

        &:hover svg.p-datatable-sort-icon {      
          visibility: visible;
        }    
      }
      .c-datatable .p-datatable-column-sorted {
        background: transparent;
      }
      .c-datatable .p-datatable-sortable-column:hover {
        background: var(--p-datatable-header-cell-hover-background);
      }

      .c-datatable-gridlines .c-datatable.p-datatable table {
        border-collapse: separate;
        border-spacing: 0px;
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-header-cell {
        border-bottom-width: 0;
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr:focus {
        box-shadow: none;
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
          box-shadow: inset 0 2px 0 0 var(--p-primary-color);
      }
      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
          box-shadow: inset 0 -2px 0 0 var(--p-primary-color);
      } 

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr > td {
        padding: 0.2rem 1rem;
        border-radius: 6px;
        height: 2.75rem;
        border: 1px solid var(--p-card-background);
        border-width: 3px 2px;
      }

      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-even {
        background: #E7F0F7;
      }
      .dark .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-even {
        background: var(--p-slate-800);
      }

      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd{
        background: #F7FAFC;
      }
      .dark .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd{
        background:  var(--p-slate-600);
      }

      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-datatable-row-selected {
        background-color: var(--default-datatable-focus-bg);
      }

      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
        background: var(--p-datatable-row-hover-background);
        color: var(--p-datatable-row-hover-color);
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-thead > tr > th {  
        padding: 0.5rem 1rem;       

        .p-datatable-column-title {
          color: var(--p-datatable-header-cell-color);
          font-family: $fontFamily;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-footer {  
        background: transparent;
        border-color: transparent;
      }  

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-tbody > tr:focus.p-datatable-row-selected,
      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr:focus.p-datatable-row-selected,
      .c-datatable-gridlines .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even):focus.p-datatable-row-selected {
          background-color: var(--default-datatable-focus-bg);      
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-datatable-paginator-bottom {
        border: none;
      }

      .c-datatable-gridlines .c-datatable.p-datatable .p-paginator-content {
        width: 100%;
      }


      /* Striped */
      .c-datatable-striped .c-datatable.p-datatable .p-datatable-tbody > tr > td,
      .c-datatable-striped .c-datatable-no-border.p-datatable .p-datatable-tbody > tr > td {
          border: none;
      }     

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-header-cell {
          border-bottom-width: 0;
      }

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-tbody > tr {
          &.p-datatable-dragpoint-top > td {
              box-shadow: inset 0 2px 0 0 var(--p-primary-color);
          }
          &.p-datatable-dragpoint-bottom > td {
              box-shadow: inset 0 -2px 0 0 var(--p-primary-color);
          } 
      }

      .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-even {
          background: #F7FAFC;
      }
      .dark .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-even {
          background: var(--p-slate-800);
      }

      .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
          background: transparent;        
      }
      .dark .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
          background: transparent;        
      }

      .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-datatable-row-selected {
          background-color: var(--default-datatable-focus-bg);
          }

      .c-datatable-striped .c-datatable.p-datatable.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
          background: var(--p-datatable-row-hover-background);
          color: var(--p-datatable-row-hover-color);
      }

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-thead > tr > th {  
          padding: 0.5rem 1rem;       

          .p-datatable-column-title {
              color: var(--p-datatable-header-cell-color);
              font-family: $fontFamily;
              font-size: 0.9rem;
              font-weight: 500;
          }
      }

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-footer {  
          background: transparent;
          border-color: transparent;
      }

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-tbody > tr:focus,
      .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr:focus,
      .c-datatable-striped .c-datatable.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even):focus {
          box-shadow: 0px 0px 0px 2px #c3d1d9 inset;

          &.p-datatable-row-selected {
          background-color: var(--default-datatable-focus-bg);      
          }        
      }

      .c-datatable-striped .c-datatable.p-datatable .p-datatable-paginator-bottom {
          border: none;
          }

      .c-datatable-striped .c-datatable.p-datatable .p-paginator-content {
          width: 100%;
      }
      `,
};

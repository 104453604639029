import { ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { discountApi } from "./DiscountApi";
import { BestDiscountedPrice } from "@/models/discount/BestDiscountedPrice";
import { ProductPrices } from "@/models/discount/ProductPrices";
import { ProductPricesRequest } from "@/models/discount/ProductPricesRequest";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useDiscountService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const loading = ref(false);

  const bestDiscountedPriceResponse = ref<BestDiscountedPrice[]>([]);

  const productPricesRequest = ref<ProductPricesRequest | null>(null);
  const productPricesResponse = ref<ProductPrices[]>([]);

  const getProductPrices = async (request: ProductPricesRequest, clearCache = false): Promise<ProductPrices[]> => {
    try {
      loading.value = true;

      if (clearCache) {
        productPricesRequest.value = null;
        bestDiscountedPriceResponse.value = [];
      }

      const authHeaders = await getAuthHeaders();

      if (
        productPricesRequest.value &&
        productPricesRequest.value?.equals(request) &&
        productPricesResponse.value !== null
      ) {
        return productPricesResponse.value;
      }

      productPricesResponse.value = await discountApi.productPrices(
        authHeaders,
        request.productIds,
        request.customerId,
      );

      productPricesRequest.value = request;
      return productPricesResponse.value;
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }

    return [];
  };

  return { getProductPrices };
};

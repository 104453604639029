import { NIL as emptyUuid } from "uuid";

export class OrderPaymentTerm {
  id: string = emptyUuid;
  name = "";
  creditDays = 0;

  constructor(id: string, name: string, creditDays: number) {
    this.id = id;
    this.name = name;
    this.creditDays = creditDays;
  }
}

<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('order.address.dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc="handleEscape"
    class="c-delivery-dialog"
  >
    <div class="c-delivery-address-dialog">
      <div class="grid grid-cols-12 gap-4 pt-2">
        <div class="col-span-12">
          <FloatLabel variant="on">
            <InputText
              v-model="delivery.customerName"
              id="delivery-customer-name"
              data-testid="delivery-customer-name"
              type="text"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.customerName.$error }"
            />
            <label for="delivery-customer-name">{{ t("order.address.customer-name") }}</label>
          </FloatLabel>
          <small class="p-error" v-if="val.customerName.$error" data-testid="delivery-customer-name-error">
            {{ val.customerName.$errors[0].$message }}
          </small>
        </div>

        <div class="col-span-12 flex gap-4">
          <div class="flex-1 text-left">
            <FloatLabel variant="on">
              <InputText
                v-model="delivery.email"
                id="delivery-email"
                data-testid="edit-delivery-email"
                type="text"
                class="inputfield w-full"
              />
              <label for="delivery-email">{{ t("order.address.email") }}</label>
            </FloatLabel>
          </div>
          <div class="flex-none w-1/3 text-left">
            <FloatLabel variant="on">
              <InputText
                v-model="delivery.phoneNumber"
                id="delivery-phone-number"
                data-testid="edit-delivery-phone-number"
                type="text"
                class="inputfield w-full"
              />
              <label for="delivery-phone-number">{{ t("order.address.phone-number") }}</label>
            </FloatLabel>
          </div>
        </div>
        <div class="col-span-12">
          <FloatLabel variant="on">
            <PrimeTextarea
              id="delivery-address-lines"
              v-model="addressLinesComputed"
              class="inputfield w-full"
              :placeholder="
                t('placeholder.type', {
                  property: t('order.address.lines').toLowerCase(),
                })
              "
              :class="{ 'p-invalid': val.address.addressLines.$error }"
              data-testid="edit-delivery-address-lines"
              :autoResize="true"
              rows="4"
            />
            <label for="delivery-address-lines">{{ t("order.address.lines") }}</label>
          </FloatLabel>
          <small class="p-error" v-if="val.address.addressLines.$error" data-testid="delivery-address-lines-error">
            {{ val.address.addressLines.$errors[0].$message }}
          </small>
        </div>
        <div class="col-span-12">
          <FloatLabel variant="on">
            <Select
              id="address-country"
              v-model="delivery.address.countryIso"
              :options="countries"
              data-testid="delivery-country"
              optionLabel="name"
              optionValue="iso"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.address.countryIso.$error }"
              :placeholder="
                t('placeholder.select', {
                  property: t('order.address.country').toLowerCase(),
                })
              "
              @show="isActive = true"
              @hide="isActive = false"
              @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
            />
            <label for="address-country">{{ t("order.address.country") }}</label>
          </FloatLabel>
          <small class="p-error" v-if="val.address.countryIso.$error" id="address-country-error">{{
            val.address.countryIso.$errors[0].$message
          }}</small>
        </div>
        <div class="col-span-12 flex gap-4">
          <div class="flex-none w-40 text-left">
            <FloatLabel variant="on">
              <InputText
                v-model="delivery.address.postalCode"
                data-testid="edit-delivery-postal-code"
                maxlength="4"
                type="text"
                class="inputfield w-full"
                :class="{ 'p-invalid': val.address.postalCode.$error }"
                :placeholder="
                  t('placeholder.type', {
                    property: t('order.address.postal-code').toLowerCase(),
                  })
                "
                @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
              />
              <label for="delivery-address-postal-code">{{ t("order.address.postal-code") }}</label>
            </FloatLabel>
            <small
              class="p-error"
              v-if="val.address.postalCode.$error"
              data-testid="delivery-address-postal-code-error"
              >{{ val.address.postalCode.$errors[0].$message }}</small
            >
          </div>
          <div class="flex-1 text-left">
            <FloatLabel variant="on">
              <InputText
                data-testid="delivery-address-city"
                type="text"
                v-model="delivery.address.city"
                class="inputfield w-full"
                :class="{ 'p-invalid': val.address.city.$error }"
                :placeholder="
                  t('placeholder.type', {
                    property: t('order.address.city').toLowerCase(),
                  })
                "
              />
              <label for="delivery-address-city">{{ t("order.address.city") }}</label>
            </FloatLabel>
            <small class="p-error" v-if="val.address.city.$error" data-testid="delivery-address-city-error">{{
              val.address.city.$errors[0].$message
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end flex-wrap card-container purple-container mt-6 mr-6">
        <PrimeButton
          :label="t(`common.cancel`)"
          data-testid="delivery-dialog-cancel-btn"
          @click="handleEscape"
          class="c-dialog-default-button"
          severity="cancel"
          text
          tabindex="1"
          autofocus
          :pt="{
            label: 'font-bold',
          }"
          icon="pi pi-times"
        />
        <PrimeButton
          :label="t('common.save')"
          data-testid="delivery-dialog-save-btn"
          @click="onSave"
          class="c-dialog-default-button c-dialog-success-button"
          icon="pi pi-check"
        />
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import cloneDeep from "lodash.clonedeep";
import { computed, nextTick, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { Country } from "@/models/country/Country";
import { useCountry } from "@/api/country/CountryService";
import { Delivery } from "@/models/order/Delivery";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  currentDelivery: Delivery;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "updateDeliveryInformation", value: Delivery): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { getCity } = useCountry();

const delivery = ref<Delivery>(props.currentDelivery ? cloneDeep(props.currentDelivery) : new Delivery());
const isActive = ref(false);

const addressLinesComputed = computed<string>({
  get: () => {
    return delivery.value.address.addressLines.join("\n");
  },
  set: (val) => {
    delivery.value.address.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  emit("updateDeliveryInformation", delivery.value);
  handleEscape();
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }

  const response = await getCity(code);
  delivery.value.address.city = response[0].city;
};

const rules = {
  customerName: {
    required,
  },
  address: {
    addressLines: {
      required,
      $each: helpers.withMessage(t("validations.required"), (value: string[]) => {
        return value !== undefined && value[0].length > 1;
      }),
    },
    postalCode: {
      required,
    },
    city: {
      required,
    },
    countryIso: {
      required,
    },
  },
};

const val = useVuelidate(rules, delivery.value);
const handleEscape = async () => {
  visible.value = false;
  await nextTick();
  document.getElementById("order-reference")?.focus();
};

const countries = ref<Country[]>([]);

const { getAllCountries } = useCountry();
const fetchCountryData = async () => {
  countries.value = await getAllCountries();
};

onMounted(() => {
  fetchCountryData();
});
</script>

<style lang="scss">
.c-delivery-address-dialog {
  padding: 0 1.5rem;
  border: none;
}

.c-delivery-dialog {
  width: 25vw;
}
</style>
